export default {
    account: {
        more: {
            rechargeAddress: "ที่อยู่เติมเงิน",
            rechargeAddressPlace: "กรุณากรอกที่อยู่เติมเงิน",
            service: "ฝ่ายบริการลูกค้าออนไลน์",
            moreMin: "ไม่น้อยกว่าจำนวนเงินถอนขั้นต่ำ",
            placePrefix: "กรุณาเลือกรหัสพื้นที่ของประเทศ",
        },
        aboutUs: {
            lint1: "ที่ BTCWEB เรามองการลงทุนแตกต่างกัน ระบบการเงินในปัจจุบันมีความซับซ้อน ต่อต้านชาวต่างชาติและมีราคาแพง ทําให้คนธรรมดาเริ่มลงทุนได้ยาก",
            lint2: "ดังนั้น เรากำลังสร้างแพลตฟอร์มที่ปราศจากอุปสรรคที่ซับซ้อน แพลตฟอร์มที่ช่วยให้คุณมองเห็นความเป็นไปได้ของเงินใหม่ ๆ และช่วยให้มันกลายเป็นจริง",
            lint3: "BTCWEB มีจุดมุ่งหมายเพื่อให้ทุกคนสามารถลงทุนได้ ดังนั้นตั้งแต่วันแรก เรามุ่งมั่นที่จะทำให้ทั้งมือใหม่หรือผู้เชี่ยวชาญที่มีประสบการณ์สามารถลงทุนในสกุลเงินดิจิทัลที่ต้องการได้ด้วยผลิตภัณฑ์ทางการเงินที่ง่ายต่อการใช้งาน",
            lint4: "อํานวยความสะดวกปฏิบัติตามกฎหมายและระเบียบข้อบังคับของแต่ละพื้นที่อย่างเคร่งครัด เราดำเนินธุรกิจของเราภายใต้ความหลากหลายของการลงทะเบียน VASP เช่นเดียวกับใบอนุญาต MiFID II, เงินอิเล็กทรอนิกส์และ PSD II ในตลาดหลักของเราทั้งหมด",
            lint5: "BTCWEB เป็นหนึ่งในบริษัทฟินเทคที่เติบโตเร็วที่สุด เรามีทีมผู้เชี่ยวชาญระดับโลกและบุคลากรด้านเทคโนโลยีที่ดีที่สุดเพื่อรับประกันว่าสินทรัพย์ดิจิทัลของผู้ใช้จะเป็นของตัวเองเสมอเพื่อเสริมสร้างจุดยืนของเราในตลาดการซื้อขายสกุลเงินดิจิทัล",
        },
        billTypes: [
            {v: 101, name: 'เติมเงิน'},
            {v: 102, name: 'การฝากเงิน'},
            {v: 103, name: 'แช่แข็ง'},
            {v: 104, name: 'ละลายน้ำแข็ง'},
            {v: 105, name: 'การฝากเงิน'},
            {v: 106, name: 'การฝากเงิน'},
            {v: 201, name: 'ถอนเงินแช่แข็ง'},
            {v: 202, name: 'ถอนเงินสด'},
            {v: 203, name: 'ถอนเงินสำเร็จ'},
            {v: 204, name: 'การถอนเงินล้มเหลว'},
            {v: 205, name: 'ค่าธรรมเนียมการถอนเงิน'},
            {v: 206, name: 'โอนออก'},
            {v: 207, name: 'โอนเข้า'},
            {v: 208, name: 'การโอนเหรียญกษาปณ์'},
            {v: 209, name: 'การโอนเหรียญกษาปณ์'},
            {v: 301, name: 'ค่าธรรมเนียมการจัดการเหรียญกษาปณ์'},
            {v: 302, name: 'ผลประโยชน์ตามสัญญา'},
            {v: 303, name: 'ขาดทุนตามสัญญา'},
            {v: 311, name: 'ซื้อตัวเลือก'},
            {v: 312, name: 'ตัวเลือกรายได้'},
            {v: 313, name: 'การคืนตัวเลือก'},
            {v: 314, name: 'ค่าธรรมเนียมการจัดการตัวเลือก'},
            {v: 315, name: 'การแช่แข็งการซื้อเหรียญ'},
            {v: 316, name: 'การหักค่าซื้อเหรียญ'},
            {v: 317, name: 'ซื้อเหรียญ ผลตอบแทน'},
            {v: 318, name: 'เหรียญซื้อเข้าบัญชี'},
            {v: 319, name: 'เหรียญซื้อเข้าบัญชี'},
            {v: 320, name: 'ขายเหรียญแช่แข็ง'},
            {v: 321, name: 'หักลดหย่อนการขายเหรียญกษาปณ์'},
            {v: 322, name: 'ขายเหรียญ ผลตอบแทน'},
            {v: 323, name: 'ขายเหรียญเข้าบัญชี'},
            {v: 324, name: 'ขายเหรียญเข้าบัญชี'},
            {v: 325, name: 'ค่าธรรมเนียมการจัดการเหรียญกษาปณ์'},
            {v: 401, name: 'Mine Machine เข้าร่วม'},
            {v: 402, name: 'เครื่องจักรเหมืองกลับ'},
            {v: 403, name: 'รายได้ของเครื่องจักร'},
            {v: 404, name: 'ถอนเครื่องขุด'},
            {v: 405, name: 'ค่าธรรมเนียมการถอนตัวของเครื่องจักร'},
            {v: 501, name: 'กล่องตาบอดซื้อ'},
            {v: 502, name: 'รายได้กล่องตาบอด'},
        ],
        withdrawTypes: [
            { v: 0, name: 'ในใบสมัคร' },
            { v: 1, name: 'ในการประมวลผล' },
            { v: 2, name: 'ความสำเร็จ' },
            { v: 3, name: 'ล้มเหลว' },
        ],
        types: [
            { v: 4, name: 'บัญชี Option' },
            { v: 3, name: 'บัญชี French' },
            { v: 2, name: 'บัญชีสัญญา' },
            { v: 1, name: 'บัญชีเหรียญ' },
        ],
        login: {
            aboutUs: "เกี่ยวกับเรา",
            notice: "เพื่อความสะดวกในการเติมเงิน กรุณาติดต่อฝ่ายบริการลูกค้าออนไลน์。",
            login: "เข้าสู่ระบบ",
            selectLanguage: "เลือกภาษา",
            placeEmail: "กล่องจดหมายหรือโทรศัพท์มือถือ",
            placePsd: "รหัสผ่าน",
            noAccount: "ไม่มีเลขที่บัญชี? ไป",
            register: "ลงทะเบียน",
            btnLogin: "เข้าสู่ระบบ",
            forget: "ลืมรหัสผ่าน?",
        },
        register: {
            register: "ลงทะเบียน",
            selectLanguage: "เลือกภาษา",
            placeEmail: "กล่องจดหมาย",
            placePsd: "รหัสผ่าน",
            placeCrmPsd: "ยืนยันรหัสผ่าน",
            placeInviteId: "รหัสเชิญ",
            haveAccount: "มีบัญชีอยู่แล้ว? ไป",
            login: "เข้าสู่ระบบ",
            btnRegister: "ลงทะเบียน",
            getCode: "รับรหัสยืนยัน",
            code: "รหัสยืนยัน",
            psd_error: "กรุณากรอกรหัสผ่านอีกครั้ง",
            psd_error2: "รหัสผ่านไม่สอดคล้องกันสองครั้ง",
            email_error: "กล่องจดหมายต้องไม่ว่างเปล่า！",
            email_error2: "รูปแบบกล่องจดหมายไม่ถูกต้อง！",
            elail_register: "ลงทะเบียนกล่องจดหมาย",
            phone_register: "ลงทะเบียนโทรศัพท์มือถือ",
            country: "ประเทศ",
            placePhone: "โทรศัพท์มือถือ",
            countryCode: "รหัสประเทศ",
            pleaseEnter: "กรุณากรอก"
        },
        forget: {
            title: "ลืมรหัสผ่าน",
            labelEmail: "อีเมล์",
            labelEmailCode: "รหัสยืนยันอีเมล",
            placeEmailCode: "รหัสยืนยันอีเมล",
        },
        forgetPay: {
            title: "ลืมรหัสผ่านการเทรด",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "บันทึกลอตเตอรี่",
        },
        Blind_box_voucher: {
            title: "บัตรกำนัล",
            voucher: "บัตรกำนัล",
            currentVoucher: "คูปองปัจจุบัน",
        },
        Blind_box: {
            title: "กล่องตาบอด",
            goodluck: "โชคดีนะ",
            panelTit: "โปรดเลือกกล่องตาบอดด้านล่าง",
            tip1: "1 รางวัลต้องชนะ 2,500 คูปอง",
            tip2: "คุณมีเวลา 2,500 ที่จะได้รับ",
            limit1: "ภาพวาดที่เหลือในวันนี้",
            limit2: "10 ครั้ง",
            times: "เวลา",
            draw: "ภาพวาด",
            discount: "ส่วนลด",
            payable: "จัดการได้",
            blind_box: "กล่องตาบอด",
            available: "ใช้ได้",
            lotteryRecord: "บันทึกลอตเตอรี่",
            Determine_blind_box: "ระบุกล่องตาบอด",
            tip3: "2. จำกัด 10 ครั้งต่อวันสำหรับการจับสลาก 1 ครั้ง และ 5 ครั้งติดต่อกัน",
            tip4: "3 การแลกคูปองสามารถแลกเปลี่ยน BTC และ ETH ที่ EXCHANG",
            tip5: "4. ลอตเตอรี่ทุกลอตเตอรี่จะถูกรางวัล",
            tip6: "5. การถอนเงิน USDT 5 ครั้งมากกว่า 10,000 ครั้งจะต้องได้รับ 'คูปองแลกเปลี่ยน' การถอนเงิน USDT 10 ครั้งมากกว่า 5,000 ครั้งจะต้องได้รับ 'คูปองแลกเปลี่ยน' การจับรางวัล USDT 20 ครั้งมากกว่า 2,500 ครั้งจะต้องได้รับ 'คูปองแลกเปลี่ยน'",
            tip7: "รางวัลลอตเตอรี่ $ 5 และความน่าจะเป็น:",
            tip8: "1. 15 ~ 200 OTD ความน่าจะเป็น 50%",
            tip9: "2. 1 ~ 5 EOS มีโอกาส 10%",
            tip10: "3. 5 ~ 30XRP, ความน่าจะเป็น 10%",
            tip11: "4. 1 ~ 10 USDT มีโอกาส 10%",
            tip12: "5. 10 ~ 50DOGE, ความน่าจะเป็น 10%",
            tip13: "6.3 ETH โดยมีความน่าจะเป็น 5%",
            tip14: "7 10 ETH, ความน่าจะเป็น 2%",
            tip15: "8.20ETH โดยมีความน่าจะเป็น 2%",
            tip16: "9 2 BTC, ความน่าจะเป็น 1%",
            tip17: "10 โบนัสการจับสลาก USDT และความน่าจะเป็น:",
            tip18: "1. 30 ~ 600 OTD ความน่าจะเป็น 50%",
            tip19: "2. 1 ~ 20EOS ความน่าจะเป็น 10%",
            tip20: "3. 10 ~ 80XRP มีโอกาส 10%",
            tip21: "4. $ 5 ~ 30 / ตันความน่าจะเป็น 10%",
            tip22: "5. 20 ~ 100DOGE, ความน่าจะเป็น 10%",
            tip23: "6 20 ETH, ความน่าจะเป็น 4%",
            tip24: "7 1 BTC, ความน่าจะเป็น 4%",
            tip25: "8.2 BTC, ความน่าจะเป็น 2%",
            tip26: "20 โบนัสการจับสลาก USDT และความน่าจะเป็น:",
            tip27: "1 5 ~ 50USDT ความน่าจะเป็น 30%",
            tip28: "2. 1 ~ 50 ADA, ความน่าจะเป็น 30%",
            tip29: "3. 1 ~ 20EOS ความน่าจะเป็น 10%",
            tip30: "4. 3DOT ความน่าจะเป็น 5%",
            tip31: "5 1 XMR ความน่าจะเป็น 5%",
            tip32: "6 1 BCH ความน่าจะเป็น 5%",
            tip33: "7 1 ETH ความน่าจะเป็น 5%",
            tip34: "8. 10,000 DOGE, ความน่าจะเป็น 5%",
            tip35: "9 2 BTC, ความน่าจะเป็น 3%",
            tip36: "10.5 BTC, ความน่าจะเป็น 2%",
            tip37: "วิธีการจับรางวัล:",
            tip38: "1. 5 USDT วาด 1 ครั้ง, 10 USDT วาด 2 ครั้ง, 20 USDT วาด 1 ครั้ง, ได้รับ 5% ปิด 5 ครั้งติดต่อกัน",
        },
    },
    Financial: {
        index: {
            finance: "การเงิน",
            ieo: "IEO",
            LockMining: "การขุดไซโลล็อค",
            day: "วัน",
            unit: "จาก",
            Daily_yield: "จาก",
        },
    },
    Follow: {
        index: {
            title: "รายชื่อเทรดเดอร์",
            tip: "ข้อมูลอัปเดตทุกชั่วโมง",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "บันทึกทางการเงิน",
            number: "ปริมาณ",
            time: "เวลา",
        },
        Assets_record: {
            title: "รายละเอียดบัญชี",
            total_assets: "สินทรัพย์รวม",
            Available_Credit: "วงเงินที่มีอยู่",
            locking: "ล็อค",
            Financial_records: "บันทึกทางการเงิน",
            number: "ปริมาณ",
            time: "เวลา",
        },
        Assets: {
            Account_record: "บันทึกการเปลี่ยนแปลงบัญชี",
            Recharge: "เติมเงิน",
            Collection: "การรับเงิน",
            Withdrawal: "ถอนเงินสด",
            transfer: "โอนเงิน",
            Currency_transfer: "เหรียญกษาปณ์",
            Coins: "เหรียญกษาปณ์",
            contract: "สัญญา",
            Legal_currency: "สกุลเงินฝรั่งเศส",
            option: "ตัวเลือก",
            Equivalent: "พับ",
        },
        FundTransfer: {
            title: "การโอนเงิน",
            Select_Currency: "เลือกสกุลเงิน",
            from: "เริ่มต้นจาก",
            Transfer_quantity: "จำนวนรอบ",
            to: "ถึง",
        },
        Receive_withdraw_record: {
            title: "บันทึก",
        },
        Receive: {
            title: "เติมเงิน",
            Digital_currency: "สกุลเงินดิจิตอล",
            tip: "ติดต่อฝ่ายบริการลูกค้าเพื่อขอที่อยู่เติมเงิน",
            next: "ขั้นตอนต่อไป",
            Recharge_address: "ที่อยู่เติมเงิน",
            Recharge_number: "จำนวนการเติมเงิน",
            place_Recharge_number: "กรุณาใส่จำนวนการเติมเงิน",
            Payment_voucher: "บัตรกำนัลการชำระเงิน",
            place_Payment_voucher: "กรุณากรอกข้อมูลหลักฐานการชำระเงิน",
        },
        Transfer: {
            Currency_transfer: "เหรียญกษาปณ์",
            Capital_transfer: "การโอนเงิน",
            from: "เริ่มต้นจาก",
            Transfer_number: "จำนวนรอบ",
            need_service_charge: "ค่าธรรมเนียมการดำเนินการเพิ่มเติม",
            to: "ถึง",
            will_get: "คุณจะได้รับ",
            Available_Credit: "วงเงินที่มีอยู่",
            Exchange_rate: "อัตราแลกเปลี่ยนเงินตราต่างประเทศ",
            Service_Charge: "ค่าธรรมเนียมการจัดการ",
        },
        Withdraw: {
            title: "ถอนเงินสด",
            Withdrawal: "ถอนเงินสด",
            bank_card: "บัตรธนาคาร",
            wallet_address: "ที่อยู่กระเป๋าสตางค์",
            Withdrawal_number: "จำนวนการถอนเงิน",
            available: "ใช้ได้",
            place_Withdrawal_number: "กรุณาใส่จำนวนการถอนเงิน",
            all: "ทั้งหมด",
            Minimum_Withdrawal_number: "จำนวนการถอนเงินขั้นต่ำ",
            password: "รหัสผ่านบัญชี",
            Service_Charge: "ค่าธรรมเนียมการจัดการ",
        }
    },
    Guess: {
        title: "ลอตเตอรี่",
        min: "ต่ำสุด",
        Bullish: "ขึ้น",
        Bearish: "ตก",
        Guess_amount: "คาดเดาจำนวนเงิน",
        place_Guess_amount: "กรุณาใส่จำนวนเงิน",
        Current_selection: "เลือกปัจจุบัน",
        Payable: "จัดการได้",
        Guess_now: "เดาตอนนี้",
        Available: "ใช้ได้",
        Lottery_record: "บันทึกลอตเตอรี่",
        Betting_record: "บันทึกการเดิมพัน",
        Start_price: "ราคาเริ่มต้น",
        End_price: "ราคาสุดท้าย",
        Game_introduction: "การแนะนำเกม",
        tip1: "1. แบบทดสอบแบ่งออกเป็น 3 ช่วงเวลา: 10 นาที, 3 นาทีและ 1 นาที BTC ทั้งขาขึ้นและขาลง ราคาของ BTC เป็นค่าเฉลี่ยของการเชื่อมโยง Coinbase + Binance + Huobi สาม, จริงๆนวนิยายที่ยุติธรรมอย่างสมบูรณ์.",
        tip2: "2. ใช้เวลา 10 นาทีเป็นตัวอย่าง: 10 นาทีแรกของแต่ละงวดคือเวลาสั่งซื้อ 2 นาทีต่อมาคือเวลาเสนอราคา 1 นาทีต่อมาคือราคาเสนอราคาที่ประกาศการเสนอราคา > ราคาเสนอราคาเป็น 'ราคาเพิ่ม', ราคาที่คาดเดาสำหรับราคาเสนอซื้อคือ 'ขาลง' ตัวอย่างเช่น: 00:00 ~ 00:15 นี่คือช่วงเวลาแรกของวันที่เริ่มต้นที่ 00:00 คุณสั่งซื้อ BTC 'Bullish' ในปริมาณการสั่งซื้อ 1,000 USDT เมื่อเวลาถึง 00:13 ราคาของ BTC อยู่ที่ 35,000 USDT ดังนั้นราคาที่ทำเครื่องหมายของคุณคือ: 35,000 USDT และราคาผลลัพธ์ที่ประกาศเวลา 00:14 คือ 35,001 USDT ดังนั้นการคาดเดาคือ 'ขาขึ้น' คุณเดาถูกแล้ว 'กระทิง' ขอแสดงความยินดีกับการคาดเดาของคุณ คุณจะได้กำไร $950 ระบบจะตั้งถิ่นฐานให้คุณ 1950 US Circle T ซึ่ง 50 US Circle T เป็นแพลตฟอร์มและคุณได้กำไร 950 US Circle T",
        tip3: "3. อัตราต่อรองคือ 1:0.95 ทำไมไม่เป็น 1:1? เพราะแพลตฟอร์มต้องเสียค่าดำเนินการ 5%",
        tip4: "4. หลังจากการทดสอบผลลัพธ์จะถูกตัดสิน ส่วนเงินที่ชนะมาจากผู้แพ้ เพื่อรับประกันว่าแพลตฟอร์มจะไม่เกี่ยวข้องกับพฤติกรรมการเล่นการพนันระหว่างผู้ใช้หากมีผู้ชนะมากและมีผู้แพ้น้อยคุณต้องรอให้ผู้แพ้ปรากฏขึ้นก่อนที่จะส่งมอบเงินรางวัล ตัวอย่างเช่น: เมื่อคุณเดิมพัน 100,000 USDT และคาดเดาว่าคุณชนะคุณควรได้รับ 95,000 USDT อย่างไรก็ตาม มีเพียง 80,000 USDT เท่านั้นที่ขาดทุน และเงิน Delivery Pool ไม่เพียงพอที่จะจ่าย 95,000 USDT ของคุณ ต้องรอให้สระว่ายน้ำส่งมอบแสดงเงินของผู้แพ้ เมื่อคุณเข้าถึง 15,000 USDT แพลตฟอร์มจะชำระโบนัส 95,000 ของคุณ",
        tip5: "5. หากคุณไม่สามารถรอที่จะเอาโบนัสได้อย่างรวดเร็วคุณสามารถทำให้การส่งมอบที่รวดเร็ว แต่การส่งมอบอย่างรวดเร็วจะได้รับเพียง 25% ของ 95,000 USDT ซึ่งหมายความว่าคุณสามารถรับได้เพียง 2375 USDT เนื่องจากการส่งมอบอย่างรวดเร็วเทียบเท่ากับโบนัสทั่วไปของคุณ อำนาจถูกถ่ายโอนไปยังแพลตฟอร์มแล้วแพลตฟอร์มต้องแบกรับความเสี่ยงสัมพัทธ์ดังนั้นแพลตฟอร์มจึงต้องหักค่าใช้จ่ายมหาศาล",
        tip6: "คำถามและคำตอบนี้ไม่เกี่ยวข้องกับการเดิมพันคู่ระหว่างแพลตฟอร์มและผู้ใช้ หากคุณพบสถานการณ์ดังกล่าวคุณสามารถรายงานได้ทันที",
    },
    home: {
        title: "",
        Total_assets: "สินทรัพย์รวม",
        Recharge: "เติมเงิน",
        documentary: "สารคดี",
        transaction: "การซื้อขาย",
        IEO: "IEO",
        Lock_mining: "การขุดไซโลล็อค",
        Investment_Financing: "การลงทุนทางการเงิน",
        Currency_transfer: "เหรียญกษาปณ์",
        Currency_swap: "แฟลชไดรฟ์",
        Withdrawal: "ถอนเงินสด",
        Blind_box: "กล่องตาบอด",
        Guess: "การคาดเดา",
        Daily_tasks: "ภารกิจประจำวัน",
        Sign_task: "ลงนามในภารกิจ",
        Buy_coins_quickly: "ซื้อเหรียญด่วน",
        Buy_quickly: "ซื้อด่วน",
        day: "วัน",
        rise: "จาก",
        Daily_yield: "อัตราผลตอบแทนรายวัน",
        quotation: "สภาวะตลาด",
        Transaction_pair: "คู่เทรด",
        Latest_price: "ราคาล่าสุด",
        Rise_fall: "ขึ้น ลง วันนี้",
    },
    ieo: {
        ieo: "IEOสมัครสมาชิก",
        Lock_up_cycle: "วงจรล็อคไซโล",
        day: "วัน",
        Closed: "จบแล้ว",
        Completed: "เสร็จสมบูรณ์",
        Subscribed: "สมัครสมาชิกแล้ว",
        surplus: "ส่วนที่เหลือ",
        My_subscription: "การสมัครของฉัน",
        Subscription_time: "เวลาซื้อ",
        currency: "สกุลเงิน",
        Requested_number: "จำนวนใบสมัคร",
        Pass_number: "ผ่านปริมาณ",
        Market_time: "เวลาทำการ",
    },
    Invest: {
        conduct: "การบริหารการเงิน",
        Special_area: "โซนพิเศษ",
        Annualized_rate: "อัตราผลตอบแทนต่อปี",
        Linked_price: "ราคาอ้างอิง Hook",
        Remaining_share: "ส่วนแบ่งที่เหลือ",
        Position_duration: "ระยะเวลาการเปิดสถานะ",
        due_date: "วันหมดอายุ",
        My_conduct: "การเงินของฉัน",
        all: "ทั้งหมด",
        fail: "ล้มเหลว",
        Purchased: "ซื้อ",
        settlement: "ในการตั้งถิ่นฐาน",
        Completed: "เสร็จสมบูรณ์",
    },
    Lockming: {
        Minimum_single: "ปากกาเดี่ยวขั้นต่ำ",
        Daily_yield: "อัตราผลตอบแทนรายวัน",
        Lock_up_cycle: "วงจรล็อคไซโล",
        payout_time: "เวลาจ่ายเงินปันผล",
        everyday: "ทุกวัน",
        Custody_funds: "เงินทุนโฮสติ้ง",
        Return_expiration: "การคืนสินค้า",
        called_away: "แลกก่อนกำหนด",
        placeholder: "โปรดป้อนเนื้อหา",
        all: "ทั้งหมด",
        Estimated_income: "ผลประโยชน์ที่คาดหวัง",
        Available_assets: "สินทรัพย์ที่มีอยู่",
        subscription: "สมัครสมาชิก",
        Lock_mining: "การขุดไซโลล็อค",
        Funds_custody: "เงินทุนที่โฮสต์",
        Estimated_yield_today: "คาดว่าผลตอบแทนวันนี้",
        Accumulated_income: "กำไรสะสม",
        Orders_custody: "การสั่งซื้อในโฮสติ้ง",
        Delegated_Order: "คำสั่งมอบหมาย",
        Lock: "ล็อคไซโล",
        day: "วัน",
        Lock_list: "รายการล็อคไซโล",
        Exited: "ออกจาก",
        Redeemed: "แลกแล้ว",
        have_hand: "กำลังดำเนินการ",
        redeem: "แลก",
        Amount_currency: "จำนวนฝาก",
        Lockup_time: "เวลาล็อคไซโล",
        Expiration_time: "เวลาหมดอายุ",
        early_redemption: "ไถ่ถอนค่าผิดนัดชำระหนี้ก่อนกำหนด",
    },
    Market: {
        Optional: "เลือกเอง",
        Transaction_pair: "คู่เทรด",
        Latest_price: "ราคาล่าสุด",
        place_search: "ค้นหาสกุลเงิน/ชื่อหุ้น/รหัสที่ท่านสนใจ",
    },
    Setting: {
        label_currency: "เลือกสกุลเงิน",
        place_currency: "กรุณาเลือกสกุลเงิน",
        label_walletTitle: "ชื่อกระเป๋าสตางค์",
        place_walletTitle: "กรุณากรอกชื่อกระเป๋าสตางค์",
        label_walletAddress: "ที่อยู่กระเป๋าสตางค์",
        place_walletAddress: "กรุณาใส่ที่อยู่กระเป๋าสตางค์",
        Add_bankCard: "เพิ่มบัตรธนาคาร",
        label_name: "ชื่อจริง",
        label_depositBank: "เปิดบัญชี LINE",
        label_bankCard: "หมายเลขบัตรธนาคาร",
        label_beneficary_address: "ที่อยู่ผู้รับประโยชน์",
        label_payee_name: "ชื่อผู้รับเงิน",
        label_bank_name: "ชื่อธนาคาร",
        label_swift_code: "รหัสธนาคาร",
        APP_download: "ใบรับรอง MSB",
        label_currentPassword: "รหัสผ่านปัจจุบัน",
        place_currentPassword: "โปรดป้อนรหัสผ่านปัจจุบัน",
        label_newPassword: "รหัสผ่านเข้าสู่ระบบใหม่",
        place_newPassword: "กรุณากรอกรหัสผ่านเข้าสู่ระบบใหม่",
        label_confirmationPassword: "ยืนยันรหัสผ่านใหม่",
        place_confirmationPassword: "กรุณากรอกรหัสผ่านเพื่อยืนยันรหัสผ่านใหม่",
        label_newPassword1: "รหัสผ่านการเทรด",
        place_newPassword1: "กรุณากรอกรหัสผ่าน",
        payment_notice: "หมายเหตุ: ค่าเริ่มต้นของรหัสผ่านการซื้อขายคือรหัสผ่านเข้าสู่ระบบปัจจุบัน",
        Real_mark1: "การรับรองตัวบุคคล",
        Real_mark2: "สิทธิ์ที่มีให้หลังจากการรับรองเสร็จสิ้น：",
        Real_mark3: "Lv1 การรับรองพื้นฐาน",
        Real_mark4: "สามารถสะท้อนได้หลังจากการรับรองขีด จำกัด 24 ชั่วโมง 200BTC",
        Real_mark5: "สามารถเทรด French Coin ได้หลังจากได้รับการรับรองวงเงิน 2000USDT เพียงครั้งเดียว",
        Real_mark6: "Lv2 การรับรองขั้นสูง",
        Real_mark7: "เพิ่มวงเงินถอนเงิน จำกัดวงเงิน 500BTC ตลอด 24 ชั่วโมง",
        Real_mark8: "เพิ่มวงเงินในการเทรด French จำนวน 100,000 USD ต่อครั้ง",
        Real_mark9: "เคล็ดลับที่อบอุ่น: เพื่อปกป้องความปลอดภัยของเงินของผู้ใช้โปรดเสร็จสิ้นการผูกกล่องจดหมายและโทรศัพท์ก่อนมิฉะนั้นการรับรองของคุณจะไม่ผ่าน",
        Real_mark10: "การรับรองพื้นฐาน",
        Real_mark11: "ได้รับการรับรอง",
        Real_mark12: "การรับรองขั้นสูง",
        Real_mark13: "ไปรับรอง",
        label_realName: "ชื่อ-นามสกุล",
        message_realName: "กรุณากรอกชื่อจริง",
        label_cardNumber: "หมายเลขบัตรประชาชน",
        message_cardNumber: "กรุณากรอกหมายเลขบัตรประชาชน",
        upload_front: "อัพโหลดหน้าบัตรประชาชน",
        upload_back: "อัพโหลดหลังบัตรประชาชน",
        upload_people: "อัปโหลดบัตรประชาชนมือถือ",
        under_review: "ในการตรวจสอบ",
        security_setting: "การตั้งค่าความปลอดภัย",
        my_bank: "บัตรธนาคารของฉัน",
        Change_Loginpsd: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
        Change_transactionpsd: "แก้ไขรหัสผ่านการซื้อขาย",
        email: "กล่องจดหมาย",
        my: "ของฉัน",
        Bank: "บัตรธนาคาร",
        AddBank: "เพิ่มบัตรธนาคาร",
        walletAddress: "ที่อยู่กระเป๋าสตางค์",
        Default_currency: "สกุลเงินฝรั่งเศสปริยาย",
        language: "ภาษา",
        share: "แบ่งปัน",
        logout: "ออกจาก",
        qrcode: "Qrcode ของฉัน",
        personal: "ข้อมูลส่วนบุคคล",
        identity: "การรับรองตัวตน",
        nickname: "ชื่อเล่น",
        nickname_tip: "2-10 ตัวอักษรสนับสนุนภาษาจีนและภาษาอังกฤษตัวเลข",
        Add_newAccount: "เพิ่มหมายเลขบัญชีใหม่",
        label_user: "ชื่อผู้ใช้",
        label_number: "หมายเลขบัตรธนาคาร",
        label_branch: "ที่อยู่ธนาคาร",
        label_bank: "ชื่อธนาคาร",
        label_swift: "การเข้ารหัส Swift",
        label_address: "ที่อยู่ผู้ใช้",
    },
    Task: {
        daily_tasks: "ภารกิจประจำวัน",
        activity_today: "กิจกรรมวันนี้",
        task_list: "รายการงาน",
        weekly_tasks: "ภารกิจประจำสัปดาห์",
        activity: "กิจกรรม",
        undone: "ยังไม่เสร็จ",
        receive: "รับ",
        task_rules: "กฎของงาน",
        task_rules1: "1: กิจกรรมจะสะสมซ้อนกันเป็นเวลาหนึ่งสัปดาห์และจะทำการหักบัญชีอัตโนมัติทุกวันจันทร์เวลา 0:00 น.",
        task_rules2: "2: รับเหรียญ CCA 5 เหรียญหลังจากเสร็จสิ้นภารกิจประจำวันทั้ง 4 ครั้งต่อวัน",
        task_rules3: "3: รับเหรียญ CCA 10 เหรียญ เมื่อแคมเปญครบ 350 เหรียญ",
        task_rules4: "4: เมื่อเหตุการณ์ถึง 700 คุณจะได้รับประสบการณ์ N1 Mine Machine เป็นเวลา 1 วัน",
        sign_task: "เข้าสู่ระบบงาน",
        Sign_everyDay: "เข้าสู่ระบบทุกวัน",
        CCA_coin: "CCAเหรียญ",
        signIn: "เข้าสู่ระบบ",
        day: "วัน",
        daily_checkIn: "เช็คอินทุกวัน",
        put_away: "วางลง",
        Expand: "ขยาย",
        signIn_now: "เข้าสู่ระบบตอนนี้",
        checkIn_rules: "กฎการเช็คอิน",
        checkIn_rules1: "1: เช็คอินติดต่อกัน 5 วันจะได้รับเหรียญ CCA 5 เหรียญ",
        checkIn_rules2: "2: รับเหรียญ CCA 20 เหรียญ เมื่อเช็คอินติดต่อกัน 14 วัน",
        checkIn_rules3: "3: 21 วันติดต่อกันเช็คอินให้รางวัล N1 Mine Machine เวลาประสบการณ์ 3 วัน",
        checkIn_rules4: "4: รักษาประวัติการเข้าพักอย่างต่อเนื่องหลังจากเข้าพักติดต่อกัน 24 วันและรับเหรียญ CCA 5 เหรียญเมื่อเข้าพักทุกวัน",
    },
    Transaction: {
        delegate_List: "รายชื่อผู้รับมอบอำนาจ",
        risk_rate: "อัตราความเสี่ยง",
        total_income: "กำไรรวมจากการเปิดสถานะ",
        oneClick_closing: "การปิดเพียงคลิกเดียว",
        optional: "เลือกเอง",
        all_closed: "ตำแหน่งทั้งหมด",
        flat_multiple: "เพียง Pingduo เดี่ยว",
        flat_empty: "แผ่นแบนเท่านั้น",
        purchase: "ซื้อ",
        sellOut: "ขาย",
        market_price: "ราคาตลาด",
        fixed_price: "ราคาจำกัด",
        trading_hands: "จำนวนล็อตการเทรด",
        multiple: "หลายเท่า",
        bond: "มาร์จิ้น",
        Service_Charge: "ค่าธรรมเนียมการจัดการ",
        balance: "ยอดคงเหลือ",
        long: "ทำ Long",
        Short: "ขาย Short",
        Price: "ราคา",
        number: "ปริมาณ",
        type: "ประเภท",
        time: "เวลา",
        operation: "การดำเนินงาน",
        Transaction_pair: "คู่เทรด",
        direction: "ทิศทาง",
        search: "ค้นหา",
        Latest_price: "ราคาล่าสุด",
        Rise_fall: "ขึ้น ลง วันนี้",
        entrust: "การมอบหมาย",
        all: "ทั้งหมด",
        entrust_time: "เวลามอบอำนาจ",
        state: "สถานะ",
        Completed: "เสร็จสมบูรณ์",
        air_completed: "เสร็จสิ้น",
        place_amount: "โปรดป้อนเนื้อหา",
        turnover: "ปริมาณการซื้อขาย",
        contract: "สัญญา",
        option: "ตัวเลือก",
        coins: "เหรียญกษาปณ์",
        High_today: "สูงวันนี้",
        Low_today: "ต่ำวันนี้",
        Entrusted_bill: "ใบปลิวมอบหมาย",
        Buy_more: "บายมอร์",
        Buy_less: "ซื้อน้อย",
        buy: "ซื้อ",
        sell: "ขาย",
        crm_order: "ยืนยันการสั่งซื้อ",
        Current_price: "ราคาปัจจุบัน",
        expiration_time: "เลือกเวลาหมดอายุ",
        Option_position: "ตัวเลือกการเปิดตำแหน่ง",
        position: "เปิดสถานะ",
        history: "ประวัติความเป็นมา",
        Purchase_price: "ราคาซื้อ",
        Purchase_amount: "ยอดซื้อ",
        Order_duration: "ระยะเวลาในการสั่งซื้อ",
        Selling_time: "เวลาขาย",
        Profit_loss: "กำไรและขาดทุน",
        Hand_count: "จำนวนมือ",
        countdown: "นับถอยหลัง",
        order_number: "หมายเลขการสั่งซื้อ",
        currency: "สกุลเงิน",
        reales_Number: "ปิดการซื้อขายจริง",
        total_price: "ราคารวม",
        close_price: "ราคาปิด",
        volume_trade: "ปริมาณการค้า",
        hand_fee: "ค่าธรรมเนียมการจัดการ",
        continue_order: "ดำเนินการตามคำสั่งซื้อ",
    },
    footer: {
        home: "หน้าหลัก",
        market: "สภาวะตลาด",
        transaction: "การซื้อขาย",
        financial: "การเงิน",
        fund: "สินทรัพย์",
        rule: "กฎ",
        select_language: "กรุณาเลือกภาษา"
    },
    common: {
        noData: "ไม่มีข้อมูลที่เกี่ยวข้องในขณะนี้",
        Exchange: "แลกเปลี่ยน",
        Cancel: "การยกเลิก",
        Confirm: "ยืนยัน",
        Submit: "ส่ง",
        more: "มากกว่า",
        add: "เพิ่ม",
        Cover: "ซ่อนเร้น",
        Success: "ความสำเร็จ",
        portion: "เสิร์ฟ",
        day: "วัน",
        please_select: "กรุณาเลือก",
        no_data: "ไม่มีข้อมูล",
    },
    script: {
        error_msg: "ตัวเลข / ตัวอักษร",
        register_noAccount: "โปรดป้อนหมายเลขโทรศัพท์ผู้ใช้หรืออีเมลผู้ใช้!",
        send_phone_scuccess: "ส่ง SMS สำเร็จ!",
        register_success: "ลงทะเบียนสำเร็จ！",
        please_enter_amount: "กรุณาใส่จำนวนเงิน！",
        please_enter_money: "กรุณาใส่เงินทุนในการซื้อ",
        success: "ความสำเร็จ!",
        bankId_msg: "กรุณาเลือกกระเป๋าสตางค์",
        amount_msg: "กรุณาใส่จำนวนเงิน",
        password_msg: "กรุณากรอกรหัสผ่าน",
        redemption_msg: "ยืนยันการแลกก่อนกำหนดหรือไม่?",
        tip: "เคล็ดลับ",
        redemption_success: "แลกสำเร็จ!",
        canceled: "ยกเลิกแล้ว",
        realname_submit_success: "ส่งชื่อจริงเรียบร้อยแล้ว!!",
        logout_tip: "คุณแน่ใจหรือว่าคุณต้องการออกจากระบบ？",
        login_tip: "คุณแน่ใจนะว่าคุณต้องการเข้าสู่ระบบ？",
        signIn: "เข้าสู่ระบบ",
        closeout_tip: "กำหนดตำแหน่งทั้งหมด?",
        closeout_success: "การปิดตำแหน่งสำเร็จทั้งหมด!",
        cancel_tip: "คุณแน่ใจนะว่าต้องการยกเลิก?",
        notice: "ประกาศ",
        insufficient_balance: "ยอดคงเหลือไม่เพียงพอ!",
    },
};
