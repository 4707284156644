export default {
    account: {
        more: {
            rechargeAddress: "Địa chỉ nạp tiền",
            rechargeAddressPlace: "Vui lòng nhập địa chỉ nạp tiền của bạn",
            service: "Dịch vụ khách hàng trực tuyến",
            moreMin: "Không được nhỏ hơn số tiền rút tối thiểu",
            placePrefix: "Vui lòng chọn mã quốc gia",
        },
        aboutUs: {
            lint1: "Tại BTCWEB, chúng tôi có một cái nhìn khác về đầu tư. Hệ thống tài chính ngày nay phức tạp, bài ngoại và tốn kém, khiến người bình thường khó bắt đầu đầu tư.",
            lint2: "Vì vậy, chúng tôi đang xây dựng một nền tảng không có rào cản phức tạp, một nền tảng cho phép bạn nhìn thấy những khả năng tiền bạc mới và giúp chúng trở thành hiện thực.",
            lint3: "BTCWEB được thiết kế để cho phép mọi người đầu tư, vì vậy ngay từ ngày đầu tiên, mục tiêu của chúng tôi là cho phép người mới hoặc các chuyên gia giàu kinh nghiệm đầu tư vào tiền điện tử mà họ muốn thông qua các sản phẩm tài chính đơn giản và dễ sử dụng.",
            lint4: "BTCWEB tuân thủ nghiêm ngặt luật pháp và quy định của từng khu vực. Chúng tôi vận hành doanh nghiệp của mình theo các đăng ký VASP khác nhau và theo giấy phép MiFID II, E-Money và PSD II trong tất cả các thị trường cốt lõi của chúng tôi",
            line5: "BTCWEB là một trong những công ty fintech phát triển nhanh nhất và chúng tôi củng cố vị trí của mình trên thị trường giao dịch tiền điện tử bằng cách có một đội ngũ chuyên gia đẳng cấp thế giới và tài năng kỹ thuật tốt nhất để đảm bảo rằng tài sản kỹ thuật số của người dùng chưa bao giờ là của riêng họ.",
        },
        billTypes: [
            {v: 101, name: 'nạp tiền'},
            {v: 102, name: 'Đặt cọc'},
            {V: 103, name: 'đóng băng'},
            {V: 104, name: 'rã đông'},
            {V: 105, name: 'Đặt cọc'},
            {v: 106, name: 'Đặt cọc'},
            {V: 201, name: 'Rút tiền đóng băng'},
            {V: 202, name: 'Rút tiền'},
            {v: 203, name: 'Rút tiền thành công'},
            {v: 204, name: 'Rút tiền thất bại'},
            {V: 205, name: 'Phí rút tiền'},
            {v: 206, name: 'Chuyển ra'},
            {v: 207, name: 'Chuyển vào'},
            {v: 208, name: 'Chuyển tiền'},
            {v: 209, name: 'Chuyển tiền vào'},
            {v: 301, name: 'Phí xử lý tiền tệ'},
            {v: 302, name: 'Lợi nhuận hợp đồng'},
            {v: 303, name: 'Hợp đồng thua lỗ'},
            {v: 311, name: 'Tùy chọn mua'},
            {v: 312, name: 'Lợi nhuận tùy chọn'},
            {v: 313, name: 'Quyền chọn trở lại'},
            {v: 314, name: 'Phí lựa chọn'},
            {v: 315, name: 'Coin mua đóng băng'},
            {v: 316, name: 'Trừ tiền mua vào'},
            {v: 317, name: 'Coin Buy Return'},
            {v: 318, name: 'Mua tiền vào tài khoản'},
            {v: 320, name: 'Bán đồng xu bị đóng băng'},
            {v: 321, name: 'Giá bán được khấu trừ'},
            {v: 322, name: 'Bán đồng xu trở lại'},
            {v: 323, name: 'Bán đồng xu vào tài khoản'},
            {v: 324, name: 'Bán đồng xu vào tài khoản'},
            {v: 325, name: 'Phí xử lý tiền tệ'},
            {v: 401, name: 'Miner join'},
            {v: 402, name: 'Miner return'},
            {v: 403, name: 'Thu nhập từ máy khai thác'},
            {v: 404, name: 'Miner exit'},
            {v: 405, name: 'Phí khai thác mỏ'},
            {v: 501, name: 'Mua hộp mù'},
            {v: 502, name: 'Thu nhập hộp mù'},
        ],
        withdrawTypes: [
            {v: 0, name: 'Đang ứng tuyển'},
            {v: 1, name: 'Đang xử lý'},
            {v: 2, name: 'Thành công'},
            {v: 3, name: 'Thất bại'},
        ],
        types: [
            {v: 4, name: 'Tài khoản tùy chọn'},
            {v: 3, name: 'Tài khoản tiền tệ'},
            {v: 2, name: 'Tài khoản hợp đồng'},
            {v: 1, name: 'Tài khoản tiền tệ'},
        ],
        login: {
            aboutUs: "Về chúng tôi",
            notice: "Để tiện nạp tiền dịch vụ, vui lòng liên hệ với dịch vụ khách hàng trực tuyến.",
            login: "Đăng nhập",
            selectLanguage: "Chọn ngôn ngữ",
            placeEmail: "Email hoặc điện thoại di động",
            placePsd: "Mật khẩu",
            noAccount: "Không có tài khoản?",
            register: "Đăng ký",
            btnLogin: "Đăng nhập",
            forget: "Quên mật khẩu?"
        },
        register: {
            register: "Đăng ký",
            selectLanguage: "Chọn ngôn ngữ",
            placeEmail: "hộp thư",
            placePsd: "Mật khẩu",
            placeCrmPsd: "Xác nhận mật khẩu",
            placeInviteId: 'Mã mời',
            haveAccount: "Đã có tài khoản chưa?",
            login: "Đăng nhập",
            btnRegister: "Đăng ký",
            getCode: "Lấy CAPTCHA",
            code: "Mã xác nhận",
            psd_error: "Vui lòng nhập lại mật khẩu",
            psd_error2: "Hai lần mật khẩu không phù hợp",
            email_error: "Hộp thư không thể để trống!",
            email_error2: "Hộp thư không đúng định dạng!",
            elail_register: "Đăng ký hộp thư",
            phone_register: "Đăng ký di động",
            country: "quốc gia",
            placePhone: "Điện thoại di động",
            countryCode: "Mã quốc gia",
            pleaseEnter: "Vui lòng nhập"
        },
        forget: {
            title: "Quên mật khẩu",
            labelEmail: "Thư điện tử",
            labelEmailCode: "Mã xác minh email",
            placeEmailCode: "Mã xác minh email",
        },
        forgetPay: {
            title: "Quên mật khẩu giao dịch",
        },
    },
    Blind_box: {
        Blind_box_record: {
            title: "The Lottery Record",
        },
        Blind_box_voucher: {
            title: "Voucher",
            voucher: "phiếu mua hàng",
            currentVoucher: "Thông tin hiện tại",
        },
        Blind_box: {
            title: "Blind box",
            goodluck: "Chúc may mắn",
            panelTit: "Vui lòng chọn hộp mù bên dưới",
            tip1: "1 lần rút thăm trúng thưởng phải trúng 2.500 phiếu giảm giá",
            tip2: "Bạn còn 2.500 lần nữa để lấy nó.",
            limit1: "Các bản vẽ còn lại của ngày hôm nay",
            limit2: "10 lần",
            times: "Lần",
            draw: "bức tranh",
            discount: "giảm giá",
            payable: "phải trả giá",
            blind_box: "hộp mù",
            available: "có thể đạt được",
            lotteryRecord: "Hồ sơ xổ số",
            Determine_blind_box: "Xác định hộp mù",
            tip3: "2. Một lần rút thăm trúng thưởng mỗi ngày giới hạn 10 lần, liên tục rút thăm trúng thưởng không giới hạn 5 lần ",
            tip4: "3. Phiếu giảm giá có thể được trao đổi cho BTC và ETH tại EXCHANG,",
            tip5: "4. Mỗi lần xổ số đều thắng.",
            tip6: "5. 5 lần rút USDT vượt quá 10.000 lần phải nhận được phiếu đổi thưởng, 10 lần rút USDT vượt quá 5.000 lần phải nhận được phiếu đổi thưởng, 20 lần rút USDT vượt quá 2.500 lần phải nhận được phiếu đổi thưởng",
            tip7: "Giải thưởng xổ số 5 đô la và xác suất:",
            tip8: "1. 15~200 OTD, xác suất 50%",
            tip9: "2. 1~5 EOS với xác suất 10%",
            tip10: "3. 5~30XRP, xác suất 10%,",
            tip11: "4. 1~10 USDT với xác suất 10%,",
            tip12: "5. 10~50DOGE, xác suất 10%,",
            tip13: "6. 3 ETH với xác suất 5%,",
            tip14: "7. 10 ETH, xác suất 2%,",
            tip15: "8. 20 ETH với xác suất 2%,",
            tip16: "9. 2 BTC, xác suất 1%,",
            tip17: "10 giải thưởng rút thăm USDT và xác suất",
            tip18: "1. 30~600 OTD, xác suất 50%,",
            tip19: "2. 1~20EOS với xác suất 10%,",
            tip20: "3. 10~80XRP với xác suất 10%,",
            tip21: "4. 5~30 USD/tấn, xác suất 10%,",
            tip22: "5. 20~100DOGE, xác suất 10%,",
            tip23: "6. 20 ETH, xác suất 4%,",
            tip24: "7. 1 BTC, xác suất 4%,",
            tip25: "8. 2 BTC, xác suất 2%,",
            Tip26: "20 USDT xổ số tiền thưởng và xác suất:",
            tip27: "1. 5~50USDT, xác suất 30%,",
            tip28: "2. 1~50 ADA, xác suất 30%,",
            tip29: "3. 1~20EOS với xác suất 10%,",
            tip30: "4. 3DOT với xác suất 5%,",
            tip31: "5. 1 XMR với xác suất 5%,",
            tip32: "6. 1 BCH với xác suất 5%,",
            tip33: "7. 1 ETH với xác suất 5%,",
            tip34: "8. 10.000 DOGE với xác suất 5%,",
            tip35: "9. 2 BTC, xác suất 3%,",
            tip36: "10. 5 BTC, xác suất 2%,",
            Tip37: "Cách rút thăm may mắn",
            tip38: "1. 5 Bơm USDT 1 lần, 10 Bơm USDT 2 lần, 20 Bơm USDT 1 lần, giảm giá 5% cho 5 Bơm liên tiếp",
        },
    },
    Financial: {
        index: {
            finance: "tài chính",
            ieo: "IEO",
            LockMining: "Khai thác mỏ khóa",
            day: "Trời",
            unit: "bắt đầu",
            Daily_yield: "Bắt đầu",
        },
    },
    Follow: {
        index: {
            title: "Danh sách các trader",
            Tip: "Dữ liệu được cập nhật hàng giờ",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "Hồ sơ tài chính",
            number: "số lượng",
            Time: "Thời gian",
        },
        Assets_record: {
            title: "Chi tiết tài khoản",
            total_assets: "Tổng tài sản tương đương",
            Available_Credit: "Hạn mức có sẵn",
            locking: "khóa",
            Financial_records: "Hồ sơ tài chính",
            number: "số lượng",
            time: "Thời gian",
        },
        Assets: {
            Account_record: "Ghi chép sổ sách",
            Recharge: "nạp tiền",
            Collection: "Thu tiền",
            Withdrawal: "rút tiền mặt",
            transfer: "chuyển khoản",
            Currency_transfer: "Chuyển đổi tiền tệ",
            Coins: "đồng xu",
            contract: "hợp đồng",
            Legal_currency: "tiền mặt",
            option: "quyền chọn",
            Equivalent: "tương ứng",
        },
        FundTransfer: {
            title: "Chuyển vốn",
            Select_Currency: "Chọn tiền tệ",
            from: "từ",
            Transfer_quantity: "Số lượng gạch ngang",
            to: "Tới",
        },
        Receive_withdraw_record: {
            title: "Hồ sơ",
        },
        Receive: {
            title: "nạp tiền",
            Digital_currency: "tiền kỹ thuật số",
            tip: "Liên hệ khách hàng yêu cầu địa chỉ nạp tiền",
            next: "Tiếp theo",
            Recharge_address: "Địa chỉ nạp tiền",
            Recharge_number: "Số tiền nạp",
            place_Recharge_number: "Vui lòng nhập số tiền nạp",
            Payment_voucher: "Chứng từ thanh toán",
            place_Payment_voucher: "Vui lòng nhập phiếu thanh toán",
        },
        Transfer: {
            Currency_Transfer: "Chuyển đổi tiền tệ",
            Capital_Transfer: "Chuyển tiền",
            from: "từ",
            Transfer_number: "Số lượng gạch ngang",
            need_service_charge: "Cần thêm phí thủ tục",
            to: "Tới",
            will_get: "Bạn sẽ nhận được",
            Available_Credit: "Hạn mức có sẵn",
            Exchange_rate: "Tỷ giá hối đoái",
            Service_Charge: "Phí thủ tục",
        },
        Withdraw: {
            title: "Rút tiền mặt",
            Withdrawal: "rút tiền mặt",
            bank_card: "thẻ ngân hàng",
            wallet_address: "Địa chỉ ví",
            Withdrawal_number: "Số lượng rút ra",
            available: "có sẵn",
            place_Withdrawal_number: "Vui lòng nhập số tiền rút ra",
            all: "Tất cả",
            Minimum_Withdrawal_number: "Số tiền rút tối thiểu",
            password: "Mật khẩu tài khoản",
            Service_Charge: "Phí thủ tục",
        }
    },
    Guess: {
        title: "Xổ số",
        min: "Tối thiểu",
        Bullish: "Tăng",
        Bearish: "Ngã",
        Guess_amount: "Đoán số lượng",
        place_Guess_amount: "Vui lòng nhập số tiền",
        Current_selection: "Chọn hiện thời",
        Payable: "Đối phó",
        Guess_now: "Bây giờ đoán xem",
        Available: "Có sẵn",
        Lottery_record: "Hồ sơ xổ số",
        Betting_record: "Hồ sơ cá cược",
        Start_price: "Giá khởi điểm",
        End_price: "Giá cuối cùng",
        Game_introduction: "Giới thiệu game",
        tip1: "1. Bài kiểm tra chia làm ba giai đoạn: 10 phút, 3 phút và 1 phút. Sự tăng và giảm của BTC Giá của BTC là giá trung bình của liên kết ba bên Coinbase+Binance+Huobi,",
        tip2: "2. Lấy ví dụ 10 phút ở đây: 10 phút đầu tiên của mỗi kỳ là thời gian đặt hàng, 2 phút sau là thời gian đặt giá thầu, 1 phút sau là thời gian đặt giá thầu công bố, và giá thầu>giá thầu là'tăng giá', Giá dự đoán cho giá thầu là'giảm', ví dụ: 00:00~00:15, đây là khoảng thời gian đầu tiên bắt đầu trong ngày, lúc 00:00 bạn đặt lệnh'tăng giá'BTC với số lượng lệnh là 1000 USDT. Khi thời gian đến 00:13, giá BTC là 35.000 USDT, sau đó giá được đánh dấu của bạn là: 35.000 USDT, trong khi giá kết quả được công bố lúc 00:14 là 35.001 USDT, sau đó đoán kết quả là'tăng', bạn đoán đúng, là'tăng giá'. Chúc mừng bạn đã đoán. Bạn sẽ kiếm được 950 đô la lợi nhuận. Hệ thống sẽ giải quyết cho bạn 1950 USD T, trong đó 50 USD T là nền tảng và bạn kiếm được 950 USD T.",
        tip3: "Tỷ lệ cược là 1: 0.95, tại sao không phải là 1: 1? Bởi vì nền tảng cần thu 5% phí thủ tục.",
        tip4: "4. Kết quả sẽ được giải quyết sau khi kết thúc bài kiểm tra. Số tiền bạn giành chiến thắng được trao cho bạn bởi những người thua cuộc. Để đảm bảo rằng nền tảng không tham gia vào hành vi đánh bạc giữa người dùng, nếu có nhiều người thắng và ít người thua, bạn sẽ cần phải đợi cho đến khi người thua xuất hiện trước khi bạn có thể giao tiền thưởng. Ví dụ: khi bạn đặt cược 100.000 USDT và đoán bạn thắng, bạn sẽ nhận được 95.000 USDT. Tuy nhiên, chỉ có 80.000 USDT bị mất, và nhóm phân phối không đủ để trả 95.000 USDT của bạn. Bạn sẽ cần phải chờ đợi cho đến khi hồ bơi giao hàng hiển thị tiền của người thua cuộc. Khi bạn đạt 15.000 USDT, nền tảng sẽ giải quyết 95.000 tiền thưởng của bạn.",
        tip5: "5. Nếu bạn không thể chờ đợi để nhận tiền thưởng nhanh chóng, bạn có thể thực hiện giao hàng nhanh, nhưng giao hàng nhanh chỉ nhận được 25% của 95.000 USDT, có nghĩa là bạn chỉ có thể nhận được 2.375 USDT vì giao hàng nhanh tương đương với tiền thưởng chung của bạn. Quyền lực chuyển cho nền tảng, như vậy nền tảng cần phải gánh chịu rủi ro tương đối, cho nên nền tảng cần khấu trừ chi phí kếch xù.",
        tip6: "Câu hỏi và câu trả lời này không liên quan đến cá cược giữa nền tảng và người dùng, và nếu bạn phát hiện ra điều này, bạn có thể báo cáo nó ngay lập tức.",
    },
    home: {
        title: "",
        Total_assets: "Tổng tài sản tương đương",
        Recharge: "Nạp tiền",
        documentary: "Tài liệu",
        transaction: "Giao dịch",
        IEO: "IEO",
        Lock_mining: "Khai thác mỏ Lockout",
        Investment_Financing: "Quản lý tài chính đầu tư",
        Currency_transfer: "Chuyển tiền xu",
        Currency_swap: "Nhấp nháy",
        Withdrawal: "Rút tiền",
        Blind_box: "Hộp mù",
        Guess: "đoán",
        Daily_tasks: "Nhiệm vụ hàng ngày",
        Sign_task: "Ký kết nhiệm vụ",
        Buy_coins_quickly: "Mua tiền nhanh",
        Buy_quickly: "Mua tiền nhanh",
        day: "Ngày",
        rise: "Bắt đầu",
        Daily_yield: "Năng suất hàng ngày",
        quotation: "Giá thị trường",
        Transaction_pair: "Cặp giao dịch",
        Latest_price: "Giá mới nhất",
        Rise_fall: "Hôm nay tăng giảm",
    },
    ieo: {
        ieo: "Đăng ký IEO",
        Lock_up_cycle: "Chu kỳ khóa vị trí",
        day: "Ngày",
        Closed: "Đã kết thúc",
        Completed: "Đã hoàn thành",
        Subscribed: "Đã đăng ký",
        surplus: "Phần còn lại",
        My_subscription: "Đăng ký của tôi",
        Subscription_time: "Thời gian mua hàng",
        currency: "Tiền tệ",
        Requested_number: "Số lượng ứng dụng",
        Pass_number: "bởi quantity",
        Market_time: "Thời gian tiếp thị",
    },
    Invest: {
        conduct: "Quản lý tài chính",
        Special_area: "Khu vực",
        Annualized_rate: "Thu nhập hàng năm",
        Linked_price: "Giá tham khảo Hook",
        Remaining_share: "Chia sẻ còn lại",
        Position_duration: "Thời hạn giữ vị trí",
        due_date: "Ngày hết hạn",
        My_conduct: "Tài chính của tôi",
        all: "Tất cả",
        fail: "Thất bại",
        Purchased: "Đã mua",
        settlement: "Đang thanh toán",
        Completed: "Đã hoàn thành",
    },
    Lockming: {
        Minimum_single: "Bút tối thiểu",
        Daily_yield: "Năng suất hàng ngày",
        Lock_up_cycle: "Chu kỳ khóa vị trí",
        payout_time: "Thời gian phân phối",
        everyday: "Hàng ngày",
        Custody_funds: "Tiền ký quỹ",
        Return_expiration: "Hết hạn trả về",
        called_away: "Mua lại sớm",
        placeholder: "Vui lòng nhập nội dung",
        all: "Tất cả",
        Estimated_income: "Lợi nhuận dự kiến",
        Available_assets: "Tài sản có sẵn",
        subscription: "Đăng ký",
        Lock_mining: "Khai thác mỏ Lockout",
        Funds_custody: "Tiền đang lưu ký",
        Estimated_yield_today: "Dự kiến lợi nhuận hôm nay",
        Accumulated_income: "Thu nhập tích lũy",
        Orders_custody: "Đặt hàng trong Hosting",
        Delegated_Order: "Lệnh ủy quyền",
        Lock: "Khóa Bin",
        day: "Ngày",
        Lock_list: "Danh sách các vị trí khóa",
        Exited: "Đã thoát",
        Redeemed: "Đã chuộc lại",
        have_hand: "Đang tiến hành",
        redeem: "Giải cứu",
        Amount_currency: "Số lượng tiền gửi",
        Lockup_time: "Thời gian khóa",
        Expiration_time: "Thời gian hết hạn",
        early_redemption: "Tiền chuộc vi phạm hợp đồng sớm",
    },
    Market: {
        Optional: "Tự chọn",
        Transaction_pair: "Cặp giao dịch",
        Latest_price: "Giá mới nhất",
        place_search: "Tìm kiếm mã tiền tệ mà bạn quan tâm",
    },
    Setting: {
        label_currency: "Chọn loại tiền tệ",
        place_currency: "Chọn loại tiền tệ",
        label_walletTitle: "Tên ví",
        place_walletTitle: "Vui lòng nhập tên ví",
        label_walletAddress: "Địa chỉ ví",
        place_walletAddress: "Vui lòng nhập địa chỉ ví",
        Add_bankCard: "Thêm thẻ ngân hàng",
        label_name: "Tên thật",
        label_depositBank: "Mở tài khoản",
        label_bankCard: "Số thẻ ngân hàng",
        label_beneficary_address: "Địa chỉ người thụ hưởng",
        label_payee_name: "Tên người nhận",
        label_bank_name: "Tên ngân hàng",
        label_swift_code: "Mã ngân hàng",
        APP_download: "MSB Chứng nhận",
        label_currentPassword: "Mật khẩu hiện tại",
        place_currentPassword: "Mật khẩu hiện tại",
        label_newPassword: "Mật khẩu đăng nhập mới",
        place_newPassword: "Mật khẩu đăng nhập mới",
        label_confirmationPassword: "Xác nhận mật khẩu mới",
        place_confirmationPassword: "Xác nhận mật khẩu mới",
        label_newPassword1: "Mật khẩu giao dịch",
        place_newPassword1: "Mật khẩu giao dịch",
        payment_notice: "Lưu ý: Giá trị mặc định ban đầu cho mật khẩu giao dịch là mật khẩu đăng nhập hiện tại",
        Real_mark1: "Nhận dạng cá nhân",
        Real_mark2: "Quyền truy cập có sẵn sau khi hoàn thành chứng nhận：",
        Real_mark3: "Lv1.Chứng nhận cơ bản",
        Real_mark4: "Chứng nhận có thể được phản ánh, giới hạn 24 giờ 200BTC",
        Real_mark5: "Có thể giao dịch fiat sau khi được chứng nhận với giới hạn duy nhất là 2000USDT",
        Real_mark6: "Lv2.Chứng nhận nâng cao",
        Real_mark7: "Tăng giới hạn rút tiền, giới hạn 24 giờ 500BTC",
        Real_mark8: "Tăng hạn mức giao dịch fiat, giới hạn 1,000,000 USDT",
        Real_mark9: "Gợi ý ấm áp: Để bảo vệ an toàn vốn của người dùng, xin hãy hoàn thành việc ràng buộc hòm thư và điện thoại trước, nếu không chứng nhận của bạn sẽ không thể thông qua.",
        Real_mark10: "Chứng nhận cơ bản",
        Real_mark11: "Chứng nhận",
        Real_mark12: "Chứng nhận nâng cao",
        Real_mark13: "Chứng nhận",
        label_realName: "Tên",
        message_realName: "Vui lòng nhập tên thật",
        label_cardNumber: "Số ID",
        message_cardNumber: "Số ID",
        upload_front: "Tải lên mặt trước của ID",
        upload_back: "Tải lên mặt sau của ID",
        upload_people: "Tải lên thẻ ID cầm tay",
        under_review: "Đang xem xét",
        security_setting: "Cài đặt bảo mật",
        my_bank: "Thẻ ngân hàng của tôi",
        Change_Loginpsd: "Thay đổi mật khẩu đăng nhập",
        Change_transactionpsd: "Thay đổi mật khẩu giao dịch",
        email: "Hộp thư",
        my: "Của tôi",
        Bank: "Thẻ ngân hàng",
        AddBank: "Thêm thẻ ngân hàng",
        walletAddress: "Địa chỉ ví",
        Default_currency: "Tiền mặt mặc định",
        language: "Ngôn ngữ",
        share: "Chia sẻ",
        logout: "Thoát",
        qrcode: "Của tôi Qrcode",
        personal: "Thông tin cá nhân",
        identity: "Chứng nhận",
        nickname: "Biệt danh",
        nickname_tip: "2-10 ký tự, hỗ trợ tiếng Trung và tiếng Anh, số",
        Add_newAccount: "Thêm tài khoản mới",
        label_user: "Tên người dùng",
        label_number: "Số thẻ ngân hàng",
        label_branch: "Địa chỉ ngân hàng",
        label_bank: "Tên ngân hàng",
        label_swift: "Mã hóa Swift",
        label_address: "Địa chỉ người dùng",
    },
    Task: {
        daily_tasks: "日常任务",
        activity_today: "今天的活动",
        task_list: "任务列表",
        weekly_tasks: "每周任务",
        activity: "活动",
        undone: "未完成的",
        receive: "接收",
        task_rules: "任务规则",
        task_rules1: "1： 活动在一周内累积叠加，每周一凌晨0点自动清零重新结算",
        task_rules2: "2： 每天完成所有4项日常任务，达到100个活动级别后获得5个CCA硬币；",
        task_rules3: "3： 当活动达到350时，您可以获得10个CCA硬币",
        task_rules4: "4： 当活动达到700时，您可以获得N1矿机1天的体验",
        sign_task: "登录任务",
        Sign_everyDay: "每天登录",
        CCA_coin: "CCA硬币",
        signIn: "登录",
        day: "天",
        daily_checkIn: "每日登记入住",
        put_away: "收起",
        Expand: "扩大",
        signIn_now: "立即登录",
        checkIn_rules: "值机规则",
        checkIn_rules1: "1： 连续签到5天将获得5枚CCA硬币",
        checkIn_rules2: "2： 连续签到14天将获得20枚CCA硬币",
        checkIn_rules3: "3： 连续21天签到，奖励N1矿机三天体验时间",
        checkIn_rules4: "4： 连续24天入住后，保持入住记录不间断，每天入住可获得5个CCA硬币",
    },
    Transaction: {
        delegate_List: "Danh sách delegate",
        risk_rate: "Tỷ lệ rủi ro",
        total_income: "Tổng lợi nhuận giữ vị trí",
        oneClick_closing: "Đóng vị trí bằng một cú nhấp chuột",
        optional: "Tự chọn",
        all_closed: "Đóng tất cả các vị trí",
        flat_multiple: "Chỉ đơn giản.",
        flat_empty: "Chỉ trống danh sách",
        purchase: "Mua vào",
        sellOut: "Bán hàng",
        market_price: "Giá thị trường",
        fixed_price: "Giới hạn",
        trading_hands: "Số lot giao dịch",
        multiple: "Nhiều",
        bond: "Ký quỹ",
        Service_Charge: "Phí xử lý",
        balance: "Số dư",
        long: "Làm nhiều",
        Short: "Ngắn",
        Price: "Giá cả",
        number: "Số lượng",
        type: "Loại",
        time: "Thời gian",
        operation: "Hoạt động",
        Transaction_pair: "Cặp giao dịch",
        direction: "Hướng dẫn",
        search: "Tìm kiếm",
        Latest_price: "Giá mới nhất",
        Rise_fall: "Hôm nay tăng giảm",
        entrust: "Trang chủ",
        all: "Tất cả",
        entrust_time: "Thời gian ủy thác",
        state: "Trạng thái",
        Completed: "Đã hoàn thành",
        air_completed: "Đã hoàn thành",
        place_amount: "Vui lòng nhập nội dung",
        turnover: "Khối lượng giao dịch",
        contract: "Hợp đồng",
        option: "Tùy chọn",
        coins: "Tiền xu",
        High_today: "Hôm nay cao",
        Low_today: "Hôm nay thấp",
        Entrusted_bill: "Lệnh chờ ủy quyền",
        Buy_more: "Mua nhiều",
        Buy_less: "Mua ít hơn",
        buy: "Mua hàng",
        sell: "Bán hàng",
        crm_order: "Xác nhận đơn hàng",
        Current_price: "Giá hiện tại",
        expiration_time: "Chọn ngày hết hạn",
        Option_position: "Quyền chọn vị trí",
        position: "Giữ vị trí",
        history: "Lịch sử",
        Purchase_price: "Giá mua",
        Purchase_amount: "Số tiền mua",
        Order_duration: "Thời gian đặt hàng",
        Selling_time: "Thời gian bán",
        Profit_loss: "Lợi nhuận và lỗ",
        Hand_count: "Số tay",
        countdown: "Đếm ngược",
        order_number: "Số thứ tự",
        currency: "Tiền tệ",
        reales_Number: "Thỏa thuận thực tế",
        total_price: "Tổng giá",
        close_price: "Giá đóng cửa",
        volume_trade: "Khối lượng giao dịch",
        hand_fee: "Phí xử lý",
        continue_order: "Tiếp tục đặt hàng",
    },
    footer: {
        home: "Trang chủ",
        market: "Giá thị trường",
        transaction: "Giao dịch",
        financial: "Tài chính",
        fund: "Tài sản",
        rule: "Quy tắc",
        select_language: "Vui lòng chọn ngôn ngữ"
    },
    common: {
        noData: "Không có dữ liệu liên quan",
        Exchange: "Trao đổi",
        Cancel: "Hủy bỏ",
        Confirm: "Xác nhận",
        Submit: "Giới thiệu",
        more: "Thêm",
        add: "Thêm",
        Cover: "Che giấu",
        Success: "Thành công",
        portion: "Phần",
        day: "Ngày",
        please_select: "Vui lòng chọn",
        no_data: "Không có dữ liệu",
    },
    script: {
        error_msg: "Số/Chữ cái",
        register_noAccount: "Vui lòng nhập số điện thoại người dùng hoặc email người dùng!",
        send_phone_scuccess: "Gửi SMS thành công!",
        register_success: "Đăng ký thành công！",
        please_enter_amount: "Vui lòng nhập số tiền！",
        please_enter_money: "Vui lòng nhập tiền mua hàng！",
        success: "Thành công!",
        bankId_msg: "Vui lòng chọn ví",
        amount_msg: "Vui lòng nhập số tiền",
        password_msg: "Vui lòng nhập mật khẩu",
        redemption_msg: "Xác nhận mua lại sớm?",
        tip: "Mẹo",
        redemption_success: "Redemption thành công!",
        canceled: "Đã hủy",
        realname_submit_success: "Tên thật Gửi thành công!",
        logout_tip: "Bạn có chắc chắn muốn đăng xuất？",
        login_tip: "Bạn có chắc chắn muốn đăng nhập？",
        signIn: "Đăng nhập",
        closeout_tip: "Xác định tất cả các vị trí đóng?",
        closeout_success: "Đóng tất cả các vị trí thành công!",
        cancel_tip: "Bạn có chắc chắn muốn hủy bỏ?",
        notice: "Thông báo",
        insufficient_balance: "Số dư không đủ!",
    },
};
