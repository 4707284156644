export default {
    account: {
        more: {
            rechargeAddress: "Adresse de recharge",
            rechargeAddressPlace: "Veuillez entrer votre adresse de recharge",
            service: "clientèle en ligne",
            moreMin: "Ne doit pas être inférieur au montant minimum du retrait en espèces",
            placePrefix: "Veuillez sélectionner un indicatif régional national",
        },
        aboutUs: {
            lint1: "At BTCWEB, we look at investing differently, Today's financial system is complex, exclusive and expensive, making it difficult for the average person to start investing,",
            lint2: "So we're building a platform without complex barriers, a platform that enables you to see new money possibilities and help them become a reality,",
            lint3: "BTCWEB is designed to make investing accessible to everyone, so from day one our goal has been to enable novices or seasoned experts to invest in the cryptocurrencies they want with easy-to-use financial products,",
            lint4: "BTCWEB strictly abides by the laws and regulations of various regions, We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets, ",
            lint5: "BTCWEB is one of the fastest growing fintech companies, We have a world-class expert team and the best technical talents to ensure that users' digital assets are always their own and to consolidate our position in the cryptocurrency trading market,",
        },
        billTypes: [
            {v : 101, name : 'top-up'},
            {v : 102, name : 'Dépôt'},
            {v : 103, name : 'Freeze'},
            {v : 104, name : 'Unfreeze'},
            {v : 105, name : 'Dépôt'},
            {v : 106, name : 'Dépôt'},
            {v : 201, name : "Retrait gelé"},
            {v : 202, nom : "Retrait"},
            {v : 203, name : "Retrait réussi"},
            {v : 204, name : 'Retrait échoué'},
            {v : 205, name : 'Frais de retrait'},
            {v : 206, name : "Transfer out"},
            {v : 207, name : "Transfer in"},
            {v : 208, nom : "Transfert de pièces vers l'extérieur"},
            {v : 209, nom : "Transfert de pièces"},
            {v : 301, nom : "Frais de pièces"},
            {v : 302, nom : "Revenu du contrat"},
            {v : 303, nom : "Pertes de contrat"},
            {v : 311, nom : "Option d'achat"},
            {v : 312, nom : "Gain d'option"},
            {v : 313, name : "Option return"},
            {v : 314, nom : "Frais d'option"},
            {v : 315, nom : "Coin buy-in freeze"},
            {v : 316, name : "Coin buy debit"},
            {v : 317, nom : "Rachat de pièces" },
            {v : 318, nom : "Achat de pièces reçu"},
            {v : 319, nom : 'Achat de pièces retourné'},
            {v : 320, name : 'Pièce vendue gelée'},
            {v : 321, nom : "Pièces vendues déduites"},
            {v : 322, name : "Retour de la vente de pièces"},
            {v : 323, name : "Pièces vendues sur le compte"},
            {v : 324, name : "Pièces revendues"},
            {v : 325, name : "Coin commission"},
            {v : 401, name : 'miner join'},
            {v : 402, nom : "Miner return"},
            {v : 403, nom : "Miner income"},
            {v : 404, nom : "Miner exit"},
            {v : 405, nom : "Frais de sortie du mineur"},
            {v : 501, nom : "Achats en aveugle"},
            {v : 502, nom : "Revenus de la boîte aveugle"},
        ],
        withdrawTypes: [
            { v : 0, name : "Application en cours" },
            { v : 1, name : 'Traitement' },
            { v : 2, name : 'Success' },
            { v : 3, name : 'Failed' },
        ],
        types: [
            { v: 4, name: 'compte d\'options' },
            { v: 3, name: 'Compte en monnaie fiduciaire' },
            { v: 2, name: 'Compte contrats' },
            { v: 1, name: 'Compte en devises' },
        ],
        login: {
            aboutUs: "A propos de nous",
            notice: "Pour recharger facilement le service, veuillez contacter le service clientèle en ligne,",
            login: "Se connecter",
            selectLanguage: "Choisir une langue",
            placeEmail: "Courriel ou téléphone portable",
            placePsd: "La langue sombre",
            noAccount: "Pas de compte ?",
            register: "Enregistrement",
            btnLogin: "Se connecter",
            forget: "Oublier?",
        },
        register: {
            register: "Enregistrement",
            selectLanguage: "Choisir une langue",
            placeEmail: "E - mail",
            placePsd: "La langue sombre",
            placeCrmPsd: "Confirmer le mot de passe",
            placeInviteId: "Code d'invitation",
            haveAccount: "Vous avez déjà un compte? Allez",
            login: "Se connecter",
            btnRegister: "Enregistrement",
            getCode: "Obtenir le Code de vérification",
            code: "Code de vérification",
            psd_error: "Veuillez saisir à nouveau votre mot de passe",
            psd_error2: "Deux mots de passe incohérents",
            email_error: "La boîte aux lettres ne peut pas être vide !",
            email_error2: "Format d'e-mail incorrect !",
            elail_register: "E - mail",
            phone_register: "Téléphone",
            country: "Pays",
            placePhone: "Téléphone",
            countryCode: "Code pays / région",
            pleaseEnter: "Veuillez entrer"
        },
        forget: {
            title: "Mot de passe oublié",
            labelEmail: "E - mail",
            labelEmailCode: "Code de vérification e - mail",
            placeEmailCode: "Code de vérification e - mail",
        },
        forgetPay: {
            title: "Mot de passe de transaction oublié",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "Record de loterie",
        },
        Blind_box_voucher: {
            title: "Le Voucher",
            voucher: "Le Voucher",
            currentVoucher: "Certificats actuels",
        },
        Blind_box: {
            title: "Boîte aveugle",
            goodluck: "Bonne chance à vous",
            panelTit: "Veuillez sélectionner une boîte aveugle ci - dessous",
            tip1: "1 tirage doit gagner 2500 coupons",
            tip2: "Vous avez encore 2500 chances",
            limit1: "Tirage restant aujourd'hui",
            limit2: "10 fois",
            times: "Nombre de fois",
            draw: "Peinture",
            discount: "Réductions",
            payable: "Payable",
            blind_box: "Boîte aveugle",
            available: "Disponible",
            lotteryRecord: "Record de loterie",
            Determine_blind_box: "Déterminer la boîte aveugle",
            tip3: "2, Un tirage de loterie limité à 10 par jour et 5 tirages consécutifs illimités",
            tip4: "3, Les coupons d'échange peuvent être échangés contre BTC et ETH chez Exchang",
            tip5: "4, Gagner à chaque loterie",
            tip6: "5, 5 retraits usdt de plus de 10 000 doivent recevoir un « bon d’échange », 10 retraits usdt de plus de 5 000 doivent recevoir un « bon d’échange » et 20 tirages usdt de plus de 2 500 doivent recevoir un « bon d’échange »,",
            tip7: "Bonus de loterie de 5 $et probabilité:",
            tip8: "1, 15 ~ 200 OTD, probabilité de 50%",
            tip9: "2, 1 ~ 5 EOS avec une probabilité de 10%",
            tip10: "3, 5 ~ 30xrp avec une probabilité de 10%",
            tip11: "4, 1 ~ 10 usdt avec une probabilité de 10%",
            tip12: "5, 10 ~ 50doge, probabilité 10%",
            tip13: "6, 3 ETH avec une probabilité de 5%",
            tip14: "7, 10 ETH, probabilité 2%",
            tip15: "8, 20eth avec une probabilité de 2%",
            tip16: "9, 2 BTC, probabilité 1%",
            tip17: "10 Usdt tombola bonus et probabilité:",
            tip18: "1, 30 ~ 600 OTD, probabilité de 50%",
            tip19: "2, 1 ~ 20eos avec une probabilité de 10%",
            tip20: "3, 10 ~ 80xrp avec une probabilité de 10%",
            tip21: "4, 5 ~ 30 USD / tonne, probabilité 10%",
            tip22: "5, 20 ~ 100doge, probabilité 10%",
            tip23: "6, 20 ETH, probabilité 4%",
            tip24: "7, 1 BTC, probabilité 4%",
            tip25: "8, 2 BTC, probabilité 2%",
            tip26: "20 Usdt tombola bonus et probabilité:",
            tip27: "1, 5 ~ 50usdt, probabilité 30%",
            tip28: "2, 1 ~ 50 Ada avec une probabilité de 30%",
            tip29: "3, 1 ~ 20eos avec une probabilité de 10%",
            tip30: "4, 3dot avec une probabilité de 5%",
            tip31: "5, 1 xmr avec une probabilité de 5%",
            tip32: "6, 1 BCH avec une probabilité de 5%",
            tip33: "7, 1 ETH avec une probabilité de 5%",
            tip34: "8, 10000 Doge avec une probabilité de 5%",
            tip35: "9, 2 BTC, 3% de probabilité",
            tip36: "10, 5 BTC, probabilité 2%",
            tip37: "Mode de tirage au sort chanceux:",
            tip38: "1, 5 usdt pull 1 fois, 10 usdt pull 2 fois, 20 usdt pull 1 fois, 5% de réduction pour 5 pulls consécutifs",
        },
    },
    Financial: {
        index: {
            finance: "Financement",
            ieo: "Société ieo",
            LockMining: "Verrouiller l'exploitation minière",
            day: "Le jour",
            unit: "Ascendant",
            Daily_yield: "Production quotidienne",
        },
    },
    Follow: {
        index: {
            title: "Liste des traders",
            tip: "Les données sont mises à jour toutes les heures",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "Dossiers financiers",
            number: "Numérique",
            time: "Le temps",
        },
        Assets_record: {
            title: "Détails du compte",
            total_assets: "Total des actifs convertis",
            Available_Credit: "Crédits disponibles",
            locking: "En suspens",
            Financial_records: "Dossiers financiers",
            number: "Numérique",
            time: "Le temps",
        },
        Assets: {
            Account_record: "Enregistrement des changements de compte",
            Recharge: "Chargement",
            Collection: "Collecte",
            Withdrawal: "Retrait",
            transfer: "Transfert",
            Currency_transfer: "Transferts monétaires",
            Coins: "Pièces de monnaie",
            contract: "Le contrat",
            Legal_currency: "Monnaie légale",
            option: "Options",
            Equivalent: "égale",
        },
        FundTransfer: {
            title: "Transfert de fonds",
            Select_Currency: "Choisir une devise",
            from: "À partir de,,,",
            Transfer_quantity: "Nombre de transferts",
            to: "à",
        },
        Receive_withdraw_record: {
            title: "Enregistrement",
        },
        Receive: {
            title: "Chargement",
            Digital_currency: "Monnaie numérique",
            tip: "Contacter le service client demander une adresse de recharge",
            next: "Prochaines étapes",
            Recharge_address: "Adresse de recharge",
            Recharge_number: "Quantité de recharge",
            place_Recharge_number: "Veuillez entrer la quantité de recharge",
            Payment_voucher: "Bon de paiement",
            place_Payment_voucher: "Veuillez entrer un justificatif de paiement",
        },
        Transfer: {
            Currency_transfer: "Transferts monétaires",
            Capital_transfer: "Transfert de fonds",
            from: "À partir de,,,",
            Transfer_number: "Nombre de transferts",
            need_service_charge: "Frais de services supplémentaires",
            to: "à",
            will_get: "Vous obtiendrez",
            Available_Credit: "Crédits disponibles",
            Exchange_rate: "Taux de change",
            Service_Charge: "Frais de service",
        },
        Withdraw: {
            title: "Compte de transfert",
            Withdrawal: "Retrait",
            bank_card: "Carte bancaire",
            wallet_address: "Mentionner l'adresse du portefeuille",
            Withdrawal_number: "Nombre de retraits",
            available: "Disponible",
            place_Withdrawal_number: "Veuillez entrer le nombre de retraits",
            all: "Tout le",
            Minimum_Withdrawal_number: "Retrait minimum",
            password: "Mot de passe du compte",
            Service_Charge: "Frais de service",
        },
    },
    Guess: {
        title: "Devinez",
        min: "Valeur minimale",
        Bullish: "Intimidation",
        Bearish: "Moustache comme",
        Guess_amount: "Devinez le montant",
        place_Guess_amount: "Veuillez entrer le montant",
        Current_selection: "Sélection actuelle",
        Payable: "Payable",
        Guess_now: "Devinez maintenant",
        Available: "Disponible",
        Lottery_record: "Record de loterie",
        Betting_record: "Record de Paris",
        Start_price: "Prix de départ",
        End_price: "Prix final",
        Game_introduction: "Introduction au jeu",
        tip1: "1, le quiz est divisé en trois périodes : 10 min, 3 min et 1 min, la hausse et la baisse du BTC, le prix du BTC est le prix moyen de Coinbase+Binance+Huobi liés ensemble, c'est un roman tout à fait équitable,",
        tip2: "2, voici un exemple de 10 minutes : les 10 premières minutes de chaque période sont consacrées à la passation d'ordres, les 2 minutes suivantes sont consacrées aux enchères, la dernière minute est consacrée à l'annonce du prix de l'offre, et le prix de l'offre > prix de l'offre est 'en hausse', Par exemple : 00:00~00:15, voici la première période au début de la journée, à 00:00 vous placez un ordre d'achat de BTC pour 1000 USDT, Lorsque l'heure atteint 00:13, le prix de BTC est de 35000 USDT, alors votre prix marqué est : 35000 USDT, Et à 00:14, le prix du résultat publié est de 35001 USDT, donc la supposition est 'à la hausse' et vous avez correctement deviné, c'est 'haussier', Félicitations pour votre estimation, Vous recevrez un profit de 950 $, Le système réglera pour vous pour 1950 USDT, dont 50 USDT pour la plateforme, et vous réaliserez un profit de 950 USDT",
        tip3: "3, La cote est de 1:0,95, pourquoi pas 1:1 ? parce que la plateforme prélève une commission de 5 %,",
        tip4: "4, Les résultats seront déterminés à la fin du quiz, L'argent que vous gagnez vous est remis par le perdant, Pour que la plate-forme ne soit pas impliquée dans des jeux d'argent entre utilisateurs, s'il y a beaucoup de gagnants et peu de perdants, vous devrez attendre que les perdants se manifestent avant de recevoir vos gains, Par exemple, lorsque vous misez 100 000 USDT et que vous pensez gagner, vous devriez recevoir 95 000 USDT, Cependant, seuls 80 000 USDT sont perdus et le pool de livraison n'a pas assez d'argent pour vous payer 95 000 USDT, Une fois que vous aurez atteint 15 000 USDT, la plateforme vous versera votre bonus de 95 000 USDT,",
        Conseil5: "5, si vous ne pouvez vraiment pas attendre et que vous voulez prendre votre bonus rapidement, vous pouvez effectuer une livraison rapide, mais la livraison rapide ne recevra que 25 % des 95 000 USDT, ce qui signifie que vous ne pouvez prendre que 2375 USDT, car la livraison rapide équivaut à votre bonus général, Le pouvoir est transféré à la plateforme, qui doit alors prendre le risque relatif, ce qui l'oblige à déduire des frais considérables",
        tip6: "Ces questions et réponses ne concernent pas les paris entre les plateformes et les utilisateurs, si vous constatez une telle situation, vous pouvez la signaler immédiatement,",
    },
    home: {
        title: "Boîte à outils",
        Total_assets: "Total des actifs convertis",
        Recharge: "Chargement",
        documentary: "Documentaire",
        transaction: "Transactions",
        IEO: "Société ieo",
        Lock_mining: "Verrouiller l'exploitation minière",
        Investment_Financing: "Financement des investissements",
        Currency_transfer: "Transferts monétaires",
        Currency_swap: "Échange",
        Withdrawal: "Retrait",
        Blind_box: "Boîte aveugle",
        Guess: "Devinez",
        Daily_tasks: "Tâches quotidiennes",
        Sign_task: "Tâches de signature",
        Buy_coins_quickly: "Achat rapide de pièces de monnaie",
        Buy_quickly: "Achat rapide",
        day: "Le jour",
        rise: "Ascendant",
        Daily_yield: "Production quotidienne",
        quotation: "Citations",
        Transaction_pair: "Traitement des transactions",
        Latest_price: "Derniers prix",
        Rise_fall: "Debout tout le monde",
    },
    ieo: {
        ieo: "Abonnement ieo",
        Lock_up_cycle: "Cycle de verrouillage",
        day: "Le jour",
        Closed: "Fermé",
        Completed: "Complet",
        Subscribed: "Déjà abonné",
        surplus: "Surplus",
        My_subscription: "Mon abonnement",
        Subscription_time: "Temps d'abonnement",
        currency: "Devises",
        Requested_number: "Nombre de demandes",
        Pass_number: "Nombre de passages",
        Market_time: "Heure du marché",
    },
    Invest: {
        conduct: "Effectuer des transactions financières",
        Special_area: "Zones spéciales",
        Annualized_rate: "Taux annualisé",
        Linked_price: "Prix associés",
        Remaining_share: "Part restante",
        Position_duration: "Durée du poste",
        due_date: "Date d'expiration",
        My_conduct: "Ma gestion financière",
        all: "Tout le",
        fail: "L'échec",
        Purchased: "Acheter",
        settlement: "Accord",
        Completed: "Complet",
    },
    Lockming: {
        Minimum_single: "Transaction unique minimum",
        Lock_up_cycle: "Cycle de verrouillage",
        payout_time: "Temps de paiement",
        everyday: "Tous les jours",
        Custody_funds: "Fonds gérés",
        Return_expiration: "Retour expiré",
        called_away: "Appelé à partir",
        placeholder: "Veuillez entrer le contenu",
        all: "Tout le",
        Estimated_income: "Revenus prévus",
        Available_assets: "Actifs disponibles",
        subscription: "S'abonner",
        Lock_mining: "Verrouiller l'exploitation minière",
        Funds_custody: "Fonds gérés",
        Estimated_yield_today: "Bénéfices attendus aujourd'hui",
        Accumulated_income: "Revenus cumulés",
        Orders_custody: "Ordres gérés",
        Delegated_Order: "Commande déléguée",
        Lock: "La serrure",
        day: "Le jour",
        Lock_list: "Liste de verrouillage",
        Exited: "A quitté",
        Redeemed: "Compensation",
        have_hand: "Avoir sous la main",
        redeem: "Rédemption",
        Amount_currency: "Montant monétaire",
        Daily_yield: "Production quotidienne",
        Lockup_time: "Temps de verrouillage",
        Expiration_time: "Temps d'expiration",
        early_redemption: "Dommages - intérêts pour remboursement anticipé",
    },
    Market: {
        Optional: "Sélectionnable",
        Transaction_pair: "Traitement des transactions",
        Latest_price: "Derniers prix",
        place_search: "Recherchez la devise / le nom du stock / le Code qui vous intéresse",
    },
    Setting: {
        label_currency: "Choisir une devise",
        place_currency: "Veuillez sélectionner une devise",
        label_walletTitle: "Nom du portefeuille",
        place_walletTitle: "Veuillez entrer un nom de portefeuille",
        label_walletAddress: "Adresse du portefeuille",
        place_walletAddress: "Veuillez entrer l'adresse du portefeuille",
        Add_bankCard: "Ajouter une carte bancaire",
        label_name: "Le vrai nom",
        label_depositBank: "Banque d'ouverture de compte",
        label_bankCard: "Numéro de carte bancaire",
        label_beneficary_address: "Adresse du bénéficiaire",
        label_payee_name: "Nom du bénéficiaire",
        label_bank_name: "Nom de la Banque",
        label_swift_code: "Code de la Banque",
        APP_download: "Certifié MSB",
        label_currentPassword: "Mot de passe actuel",
        place_currentPassword: "Veuillez entrer votre mot de passe actuel",
        label_newPassword: "Nouveau login mot de passe",
        place_newPassword: "Veuillez entrer un nouveau mot de passe",
        label_confirmationPassword: "Confirmer le nouveau mot de passe",
        place_confirmationPassword: "Veuillez entrer un nouveau mot de passe pour confirmer",
        label_newPassword1: "Transaction Password",
        place_newPassword1: "Veuillez saisir votre mot de passe de transaction",
        payment_notice: "Note : La valeur initiale par défaut du mot de passe de la transaction est le mot de passe de connexion actuel",
        Real_mark1: "Vérification de l'identité personnelle",
        Real_mark2: "Autorisations disponibles après la certification:",
        Real_mark3: "Certification de base niveau 1",
        Real_mark4: "Peut être reflété après la certification, avec un plafond de 24 heures de 200 BTC",
        Real_mark5: "Après la certification, il est possible d'effectuer des transactions en monnaie française avec une limite de transaction unique de 2000usdt",
        Real_mark6: "Certification avancée de niveau II",
        Real_mark7: "Augmentation de la limite réfléchie avec une limite de 24 heures de 500 BTC",
        Real_mark8: "Augmentation de la limite de transaction en monnaie française avec une limite unique de 1 000 000 $",
        Real_mark9: "Conseil chaleureux: pour sécuriser les fonds des utilisateurs, veuillez d'abord compléter la liaison de l'e - mail et du téléphone, sinon votre authentification échouera,",
        Real_mark10: "Certification de base",
        Real_mark11: "Confirmé",
        Real_mark12: "Certification avancée",
        Real_mark13: "Annulation de la certification",
        label_realName: "Le vrai nom",
        message_realName: "Veuillez entrer votre vrai nom",
        label_cardNumber: "Numéro de carte d'identité",
        message_cardNumber: "Veuillez entrer le numéro d'identification",
        upload_front: "Télécharger la carte d'identité Front",
        upload_back: "Télécharger le dos de la carte d'identité",
        upload_people: "Télécharger une carte d'identité à main levée",
        under_review: "En cours de révision",
        security_setting: "Paramètres de sécurité",
        my_bank: "Ma carte bancaire",
        Change_Loginpsd: "Modifier le mot de passe de connexion",
        Change_transactionpsd: "Modifier le mot de passe de la transaction",
        email: "E - mail",
        my: "Le mien",
        Bank: "Carte bancaire",
        AddBank: "Ajouter une carte bancaire",
        walletAddress: "Adresse du portefeuille",
        Default_currency: "Monnaie légale par défaut",
        language: "Langue",
        share: "Au total",
        logout: "Déconnexion",
        qrcode: "Mon QRCode",
        personal: "Informations personnelles",
        identity: "Authentification",
        nickname: "Surnom",
        nickname_tip: "2 - 10 caractères, support chinois - anglais, numérique",
        Add_newAccount: "Ajouter un nouveau compte",
        label_user: "Nom de l'utilisateur",
        label_number: "Numéro de carte bancaire",
        label_branch: "Adresse de la Banque",
        label_bank: "银行名称",
        label_swift: "Codage Swift",
        label_address: "Adresse de l'utilisateur",
    },
    Task: {
        daily_tasks: "Tâches quotidiennes",
        activity_today: "Événements aujourd'hui",
        task_list: "Liste des tâches",
        weekly_tasks: "Missions hebdomadaires",
        activity: "Activités",
        undone: "Inachevé",
        receive: "Recevoir",
        task_rules: "Règles de mission",
        task_rules1: "1: les activités accumulent des superpositions pendant la semaine, sont automatiquement vidées et réglées à 0 h du matin chaque semaine,；",
        task_rules2: "2: terminez tous les 4 tâches quotidiennes tous les jours et obtenez 5 pièces CCA après avoir atteint le niveau d'activité 100；",
        task_rules3: "3：Lorsque l'activité atteint 350, vous pouvez obtenir 10 pièces CCA；",
        task_rules4: "4： Lorsque l'activité atteint 700, vous obtenez une expérience de 1 jour avec la mine N1",
        sign_task: "Connexion aux tâches",
        Sign_everyDay: "Login tous les jours",
        CCA_coin: "Pièces de monnaie CCA",
        signIn: "Se connecter",
        day: "Le jour",
        daily_checkIn: "Check - in quotidien",
        put_away: "Fermer",
        Expand: "Élargissement",
        signIn_now: "Connectez - vous maintenant",
        checkIn_rules: "Règles d'inscription",
        checkIn_rules1: "1：Vous recevrez 5 pièces CCA pendant 5 jours consécutifs；",
        checkIn_rules2: "2：Connectez - vous pendant 14 jours consécutifs pour recevoir 20 pièces CCA；",
        checkIn_rules3: "3：Connectez - vous pendant 21 jours consécutifs, Récompensez trois jours d'expérience n1 Minerals；",
        checkIn_rules4: "4：Gardez un enregistrement d'enregistrement ininterrompu après 24 jours consécutifs d'enregistrement et recevez 5 pièces CCA par jour d'enregistrement；",
    },
    Transaction: {
        delegate_List: "Liste des représentants",
        risk_rate: "Taux de risque",
        total_income: "Revenu total du poste",
        oneClick_closing: "Fermeture en un clic",
        optional: "Sélectionnable",
        all_closed: "Toutes les positions fermer",
        flat_multiple: "Commande unique et commandes multiples uniquement",
        flat_empty: "Seuls les billets plats et vides",
        purchase: "Acheter",
        sellOut: "Vendu",
        market_price: "Prix du marché",
        fixed_price: "Prix fixe",
        trading_hands: "Mains de Trading",
        multiple: "Multiples",
        bond: "Le lien",
        Service_Charge: "Frais de service",
        balance: "Équilibre",
        long: "Longue",
        Short: "Courte",
        Price: "Prix",
        number: "Numérique",
        type: "Type",
        time: "Le temps",
        operation: "Activités",
        Transaction_pair: "Traitement des transactions",
        direction: "Direction",
        search: "Rechercher",
        Latest_price: "Derniers prix",
        Rise_fall: "Ascendant - descendant",
        entrust: "Délégation",
        all: "Tout le",
        entrust_time: "Temps de délégation",
        state: "Statut",
        Completed: "Complet",
        air_completed: "Air terminé",
        place_amount: "Veuillez entrer le contenu",
        turnover: "Taux de changement de personnel",
        contract: "Le contrat",
        option: "Options",
        coins: "Pièces de monnaie",
        High_today: "Très haut aujourd'hui",
        Low_today: "Le plus bas aujourd'hui",
        Entrusted_bill: "Ticket délégué",
        Buy_more: "Acheter plus",
        Buy_less: "Acheter moins",
        buy: "Acheter",
        sell: "Vendre",
        crm_order: "Confirmer la commande",
        Current_price: "Prix actuel",
        expiration_time: "Choisir une date d'expiration",
        Option_position: "Position des options",
        position: "Localisation",
        history: "Historique",
        Purchase_price: "Prix d'achat",
        Purchase_amount: "Montant de l'achat",
        Order_duration: "Durée de la commande",
        Selling_time: "Temps de vente",
        Profit_loss: "Gains et pertes",
        Hand_count: "Nombre de mains",
        countdown: "Compte à rebours",
        order_number: "Numéro de commande",
        currency: "Devises",
        reales_Number: "Transactions réelles",
        total_price: "Prix total",
        close_price: "Prix de clôture",
        volume_trade: "Volume du commerce",
        hand_fee: "Frais de traitement",
        continue_order: "Continuer à passer une commande",
    },
    footer: {
        home: "Maison",
        market: "Le Bazar",
        transaction: "Transactions",
        financial: "Financière",
        fund: "Le Fonds",
        rule: "Règles",
        select_language: "Veuillez sélectionner une langue"
    },
    common: {
        noData: "Aucune donnée pertinente pour le moment",
        Exchange: "Échange",
        Cancel: "Annulation",
        Confirm: "Confirmé",
        Submit: "Soumettre",
        more: "Plus",
        add: "Ajouter",
        Cover: "Caché",
        Success: "Succès",
        portion: "Part",
        day: "Jours",
        please_select: "Veuillez sélectionner",
        no_data: "Pas de données",
    },
    script : {
        error_msg : "chiffres/lettres",
        register_noAccount : "Veuillez saisir votre numéro de téléphone ou votre adresse électronique !",
        send_phone_scuccess : "Envoi d'un téléphone réussi !",
        register_success : "Enregistrement réussi",
        please_enter_amount : "Veuillez entrer le montant",
        please_enter_money : "Veuillez entrer l'argent de l'achat",
        success : "SUCCESS !",
        bankId_msg : "Veuillez sélectionner un portefeuille",
        amount_msg : "Veuillez saisir le montant",
        password_msg : "Veuillez saisir votre mot de passe",
        redemption_msg : "Remboursement anticipé confirmé?",
        tip : "Conseils",
        redemption_success : "Rachat réussi!",
        annulé : "Annulé",
        realname_submit_success : "Realname Submit Successed !",
        logout_tip : "Êtes-vous sûr de vous déconnecter？",
        login_tip : "Êtes-vous sûr de vouloir vous connecter",
        signIn : "Sign in",
        closeout_tip : "Déterminer toutes les positions fermées ?",
        closeout_success : "Toutes les positions ont été clôturées avec succès !",
        cancel_tip : "Are you sure Cancel ?",
        notice : "Notice",
        insufficient_balance : "Solde insuffisant !",
    },
};
