export default {
    account: {
        more: {
            rechargeAddress: "Aufladeadresse",
            rechargeAddressPlace: "Bitte geben Sie Ihre Aufladeadresse ein",
            service: "Online Service",
            moreMin: "Nicht geringer als der minimale Auszahlungsbetrag",
        },
        aboutUs: {
            lint1: "Bei BTCWEB unterscheiden sich unsere Ansichten über Investitionen. Das derzeitige Finanzsystem ist komplex, exklusiv und teuer, was es für gewöhnliche Menschen schwierig macht, mit Investitionen zu beginnen",
            lint2: "Deshalb bauen wir eine Plattform ohne komplexe Hindernisse auf, die es IHnen erm ö glicht, neue finanzielle M ö glichkeiten zu sehen und sie Wirkung werden zu lassen",
            lint3: "BTCWEB zielt darauf ab, jedem zu ermöglichen, zu investieren. Unser Ziel ist es daher, Anfängern oder erfahrenen Experten vom ersten Tag an zu ermöglichen, mit einfachen und benutzerfreundlichen Finanzprodukten in die gewünschte Kryptowährung zu investieren.",
            lint4: "BTCWEB hält sich strikt an lokale Gesetze und Vorschriften. Wir betreiben unser Geschäft auf Basis verschiedener VASP-Registrierungen und MiFID II-, elektronische Währung- und PSD II-Lizenzen in allen unseren Kernmärkten.",
            lint5: "BTCWEB ist eines der am schnellsten wachsenden Finanztechnologieunternehmen. Wir verfügen über ein erstklassiges Expertenteam und die besten technischen Talente, um sicherzustellen, dass die digitalen Vermögenswerte der Benutzer immer ihre eigenen sind, um unsere Position auf dem Kryptowährungshandelsmarkt zu festigen.",
        },
        billTypes: [
            {v: 101, name: 'Geld aufladen'},
            {v: 102, name: 'Kaution'},
            {v: 103, name: 'Einfrieren'},
            {v: 104, name: 'Auftauen'},
            {v: 105, name: 'Kaution'},
            {v: 106, name: 'Kaution'},
            {v: 201, name: 'Einfrieren der Rücknahme'},
            {v: 202, name: '提现'},
            {v: 203, name: 'Auszug erfolgreich'},
            {v: 204, name: 'Auszug fehlschlagen'},
            {v: 205, name: 'Bearbeitungsgebühr für Auszahlungen'},
            {v: 206, name: 'Transferaus'},
            {v: 207, name: 'Transfer in'},
            {v: 208, name: 'Währungstransfer'},
            {v: 209, name: 'Währungstransfer in'},
            {v: 301, name: 'Gebühren für die Währungsabwicklung'},
            {v: 302, name: 'Vertragseinkommen'},
            {v: 303, name: 'Vertragsverluste'},
            {v: 311, name: 'Kaufoptionen'},
            {v: 312, name: 'Option zurück'},
            {v: 313, name: 'Option Rückerstattung'},
            {v: 314, name: 'Bearbeitungsgebühr für Optionen'},
            {v: 315, name: 'Einfrieren des Währungskaufs'},
            {v: 316, name: 'Abzug des Währungskaufs'},
            {v: 317, name: 'Rückgabe des Münzkaufs'},
            {v: 318, name: 'Geld steigt ins konto ein'},
            {v: 319, name: 'Geld steigt ins konto ein'},
            {v: 320, name: 'Die münzen wurden verkauft'},
            {v: 321, name: 'Geld wird verkauft'},
            {v: 322, name: 'Geld wird verkauft und zurückgeschickt'},
            {v: 323, name: 'Geld wird verkauft'},
            {v: 324, name: 'Geld wird verkauft'},
            {v: 325, name: 'Gebühren und gebühren in münzen'},
            {v: 401, name: 'Zugabe von Bergbaumaschinen'},
            {v: 402, name: 'Rückgabe von Bergbaumaschinen'},
            {v: 403, name: 'Einnahmen aus Bergbaumaschinen'},
            {v: 404, name: 'Ausgang der Bergbaumaschine'},
            {v: 405, name: 'Bearbeitungsgebühr für den Ausstieg von Bergbaumaschinen'},
            {v: 501, name: 'Kauf von Blind Box'},
            {v: 502, name: 'Blind Box Einnahmen'},
        ],
        withdrawTypes: [
            { v: 0, name: 'Beantragung' },
            { v: 1, name: 'Verarbeitung' },
            { v: 2, name: 'Erfolg' },
            { v: 3, name: 'fehlschlagen' },
        ],
        types: [
            { v: 4, name: 'Optionskonto' },
            { v: 3, name: 'Gesetzliches Währungskonto' },
            { v: 2, name: 'Vertragskonto' },
            { v: 1, name: 'Münzkonto' },
        ],
        login: {
            aboutUs: "Über uns",
            notice: "Für eine bequeme Serviceaufladung wenden Sie sich bitte an den Online-Kundenservice。",
            login: "Login",
            selectLanguage: "Sprache auswählen",
            placeEmail: "E-Mail oder Mobiltelefon",
            placePsd: "Passwort",
            noAccount: "Kein Konto？gehen",
            register: "Registrieren Sie ein Konto",
            btnLogin: "Login",
            forget: "Passwort vergessen?",
        },
        register: {
            register: "Registrieren Sie ein Konto",
            selectLanguage: "Sprache auswählen",
            placeEmail: "Postfach",
            placePsd: "Passwort",
            placeCrmPsd: "Passwort bestätigen",
            placeInviteId: "Einladungscode",
            haveAccount: "Es gibt bereits ein Konto？gehen",
            login: "Login",
            btnRegister: "Registrieren Sie ein Konto",
            getCode: "Überprüfungscode erhalten",
            code: "Prüfcode",
            psd_error: "Bitte geben Sie das Passwort erneut ein",
            psd_error2: "Die beiden Passwörter stimmen nicht überein",
            email_error: "E-Mail kann nicht leer sein！",
            email_error2: "Falsches E-Mail-Format！",
            elail_register: "E-Mail Registrierung",
            phone_register: "Mobile Registrierung",
            country: "Land",
            placePhone: "Handy",
            countryCode: "Ländercode",
            pleaseEnter: "Bitte geben Sie ein"
        },
        forget: {
            title: "Passwort vergessen",
            labelEmail: "E-Mail",
            labelEmailCode: "E-Mail-Verifizierungscode",
            placeEmailCode: "E-Mail-Verifizierungscode",
        },
        forgetPay: {
            title: "Transaktionskennwort vergessen",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "Lotterierekord",
        },
        Blind_box_voucher: {
            title: "Gutschein",
            voucher: "Gutschein",
            currentVoucher: "Aktueller Gutschein",
        },
        Blind_box: {
            title: "Blindbox",
            goodluck: "Ich wünsche Ihnen viel Glück",
            panelTit: "Bitte wählen Sie die Blindbox unten aus",
            tip1: "1 Lotterie muss 2500 Coupons gewinnen",
            tip2: "Sie haben immer noch 2500 mehr Male, um es zu bekommen",
            limit1: "Die restlichen Zeichnungen für heute",
            limit2: "10-mal",
            times: "in Folge",
            draw: "Malerei",
            discount: "Rabatt",
            payable: "Zahlbar",
            blind_box: "Blindbox",
            available: "Verfügbar",
            lotteryRecord: "Lotterierekord",
            Determine_blind_box: "Blindbox bestimmen",
            tip3: "2. Eine Lottoziehung ist auf 10-mal pro Tag begrenzt und nicht mehr als fünf aufeinanderfolgende Ziehungen sind zulässig",
            tip4: "3. Einlösegutscheine können bei EXCHANGE für BTC und ETH eingelöst werden",
            tip5: "4. Jede Lotterie gewinnt den Preis",
            tip6: "5. 5-USDT-Auszahlungen über dem 10000mal müssen einen 'Einlösungsgutschein' erhalten, 10-USDT-Auszahlungen über dem 5000-Mal müssen einen 'konvertierbaren Gutschein' erhalten, und 20-USDT-Ziehungen über dem 2500-Mal müssen einen 'Tauschgutschein' erhalten.",
            tip7: "5Dollar Lotterie Bonus und Wahrscheinlichkeit:",
            tip8: "1. 15~200 OTD，Wahrscheinlichkeit 50%",
            tip9: "2. 1~5个EOS，Wahrscheinlichkeit beträgt 10%",
            tip10: "3. 5~30XRP，Wahrscheinlichkeit 10%",
            tip11: "4. 1~10 USDT，Wahrscheinlichkeit 10%%",
            tip12: "5. 10~50DOGE，Wahrscheinlichkeit 10%",
            tip13: "6. 3 ETH，Wahrscheinlichkeit 5%",
            tip14: "7. 10 ETH，Wahrscheinlichkeit 2%",
            tip15: "8. 20ETH，Wahrscheinlichkeit 2%",
            tip16: "9. 2 BTC，Wahrscheinlichkeit 1%",
            tip17: "10 USDT Lotterie Bonus und Wahrscheinlichkeit:",
            tip18: "1. 30~600 OTD，Wahrscheinlichkeit 50%",
            tip19: "2. 1~20EOS，Wahrscheinlichkeit beträgt 10%",
            tip20: "3. 10~80XRP，Wahrscheinlichkeit beträgt 10%",
            tip21: "4. 5-30 USD/ton, mit einer Wahrscheinlichkeit von 10%",
            tip22: "5. 20~100DOGE，Wahrscheinlichkeit 10%%",
            tip23: "6. 20 ETH，Wahrscheinlichkeit 4%",
            tip24: "7. 1 BTC，Wahrscheinlichkeit 4%",
            tip25: "8. 2 BTC，Wahrscheinlichkeit 2%",
            tip26: "20 USDT Lotterie Bonus und Wahrscheinlichkeit:",
            tip27: "1. 5~50USDT，30% Wahrscheinlichkeit",
            tip28: "2. 1~50 ADA，30% Wahrscheinlichkeit",
            tip29: "3. 1~20EOS，Wahrscheinlichkeit ist 10%",
            tip30: "4. 3DOT，Wahrscheinlichkeit beträgt 5%",
            tip31: "5. 1个XMR，Wahrscheinlichkeit beträgt 5%",
            tip32: "6. 1 BCH，Wahrscheinlichkeit beträgt 5%",
            tip33: "7. 1 ETH，Wahrscheinlichkeit beträgt 5%",
            tip34: "8. 10000 DOGE，Wahrscheinlichkeit beträgt 5%",
            tip35: "9. 2 BTC，3% Wahrscheinlichkeit",
            tip36: "10. 5 BTC，2% Wahrscheinlichkeit",
            tip37: "Lucky Draw Methode:",
            tip38: "1. Zeichnen Sie einmal für 5 USDT, zweimal für 10 USDT, einmal für 20 USDT, und genießen Sie einen 5% Rabatt für 5 aufeinanderfolgende Ziehungen",
        },
    },
    Financial: {
        index: {
            finance: "Finanzierung",
            ieo: "IEO",
            LockMining: "Abbau sperren",
            day: "Tag",
            unit: "steigen",
            Daily_yield: "steigen",
        },
    },
    Follow: {
        index: {
            title: "Händlerliste",
            tip: "Die Daten werden stündlich aktualisiert",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "Finanzbuchhaltung",
            number: "Menge",
            time: "Zeit",
        },
        Assets_record: {
            title: "Kontodetails",
            total_assets: "Gesamte umgewandelte Vermögenswerte",
            Available_Credit: "Verfügbare Gutschrift",
            locking: "Verriegelung",
            Financial_records: "Finanzbuchhaltung",
            number: "Menge",
            time: "Zeit",
        },
        Assets: {
            Account_record: "Änderungsdaten in der Buchhaltung",
            Recharge: "Geld aufladen",
            Collection: "Geld sammeln",
            Withdrawal: "Auszahlung von Bargeld",
            transfer: "Transfer",
            Currency_transfer: "Währungstransfer",
            Coins: "Münzen",
            contract: "Vertrag",
            Legal_currency: "Fiat Währung",
            option: "Option",
            Equivalent: "Äquivalent",
        },
        FundTransfer: {
            title: "Mittelübertragung",
            Select_Currency: "Währung auswählen",
            from: "von",
            Transfer_quantity: "Übertragungsmenge",
            to: "zu",
        },
        Receive_withdraw_record: {
            title: "notieren",
        },
        Receive: {
            title: "Geld aufladen",
            Digital_currency: "Digitale Währung",
            tip: "Kontaktieren Sie den Kundendienst, um eine Aufladeadresse anzufordern",
            next: "Nächster Schritt",
            Recharge_address: "Aufladeadresse",
            Recharge_number: "Aufladungsmenge",
            place_Recharge_number: "Bitte geben Sie die Lademenge ein",
            Payment_voucher: "Zahlungsbeleg",
            place_Payment_voucher: "Bitte Zahlungsbeleg eingeben",
        },
        Transfer: {
            Currency_transfer: "Währungstransfer",
            Capital_transfer: "Mittelübertragung",
            from: "von",
            Transfer_number: "Übertragungsmenge",
            need_service_charge: "Zusätzliche Bearbeitungsgebühr erforderlich",
            to: "zu",
            will_get: "Sie erhalten",
            Available_Credit: "Verfügbare Gutschrift",
            Exchange_rate: "Wechselkurs",
            Service_Charge: "Bearbeitungsgebühren",
        },
        Withdraw: {
            title: "Auszahlung von Bargeld",
            Withdrawal: "Auszahlung von Bargeld",
            bank_card: "Bankkarte",
            wallet_address: "Wallet-Adresse",
            Withdrawal_number: "Entnahmemenge",
            available: "verfügbar",
            place_Withdrawal_number: "Bitte geben Sie die Entnahmemenge ein",
            all: "ganz",
            Minimum_Withdrawal_number: "Mindestentnahmemenge",
            password: "Passwort für das Konto",
            Service_Charge: "Bearbeitungsgebühren",
        }
    },
    Guess: {
        title: "Lotterie",
        min: "Minimum",
        Bullish: "steigen",
        Bearish: "Fall",
        Guess_amount: "Betrag erraten",
        place_Guess_amount: "Bitte geben Sie den Betrag ein",
        Current_selection: "Aktuelle Auswahl",
        Payable: "Zahlbar",
        Guess_now: "Jetzt raten",
        Available: "Verfügbar",
        Lottery_record: "Lotterierekord",
        Betting_record: "Wettrekord",
        Start_price: "Ausgangspreis",
        End_price: "Endpreis",
        Game_introduction: "Spieleinführung",
        tip1: "1. Der Test ist in drei Perioden unterteilt: 10 Minuten, 3 Minuten und eine Minute. Aufstieg und Fall der BTC. Der Preis von BTC ist der Durchschnittspreis von Coinbase, Binance und Huobi, was ein völlig fairer Roman ist.",
        tip2: "2.Nehmen Sie hier zehn Minuten als Beispiel: die ersten zehn Minuten jeder Sitzung sind die Bestellzeit, die letzten zwei Minuten sind die Gebotszeit, die letzte 1 Minute ist der angekündigte Gebotspreis und der Gebotspreis>Gebotspreis ist 'Markup'., Der spekulative Preis des Gebotspreises ist 'unten', zum Beispiel: 00:00~00:15, was die erste Periode des Tages ist. Bei 00:00 haben Sie eine BTC-bullische 'Order' mit einem Orderbetrag von 1000 USDT platziert. Wenn die Zeit 00:13 erreicht, ist der Preis von BTC 35000 USDT, also Ihr markierter Preis ist: 35000 USDT, und der Ergebnispreis, der bei 00:14 angekündigt wird, ist 350001 USDT, also ist die Vermutung 'hoch', 'Sie haben es richtig erraten, es ist 'bullish'. Herzlichen Glückwunsch zu Ihren Spekulationen. Sie erhalten einen Gewinn von 950€. Das System wird 50-US-Dollar T für Sie abrechnen, von denen 50-US-Dollar T die Plattform ist, und Sie werden einen Gewinn von 950 US-Dollar T machen",
        tip3: "3. Die Chancen sind 1:0.95, warum nicht 1:1? Weil die Plattform eine 5% Bearbeitungsgebühr erfordert。",
        tip4: "4. Nach dem Test werden die Ergebnisse abgerechnet. Das Geld, das Sie gewinnen, wird Ihnen vom Verlierer gegeben. Um sicherzustellen, dass die Plattform nicht am Spielverhalten zwischen Nutzern teilnimmt, müssen Sie, wenn es viele Gewinner und wenige Verlierer gibt, warten, bis die Verlierer erscheinen, bevor Sie den Bonus bezahlen können. Wenn Sie zum Beispiel 100000 USDT wetten und spekulieren, dass Sie gewonnen haben, sollten Sie 95000 USDT erhalten. Allerdings geht nur 80000 USDT verloren, und das Geld des Zahlungspools reicht nicht aus, um Ihre 95000 USDT zu bezahlen. Sie müssen warten, bis der Lieferpool die Gelder der Verlierer anzeigt. Sobald Sie 15000 USDT erreicht haben, wird die Plattform Ihren 95000 Bonus begleichen.",
        tip5: "5. Wenn Sie es wirklich nicht erwarten können, den Bonus schnell zu nehmen, können Sie mit einer schnellen Lieferung fortfahren, aber schnelle Lieferung kann nur 25% von 95000 USDT erhalten, was bedeutet, dass Sie nur 2375 USDT nehmen können, da schnelle Lieferung Ihrem regulären Bonus entspricht. Wenn Strom auf die Plattform übertragen wird, muss die Plattform relative Risiken tragen, sodass die Plattform enorme Gebühren abziehen muss.",
        tip6: "Diese Frage und Antwort beinhaltet kein Glücksspiel zwischen der Plattform und den Nutzern. Wenn Sie eine solche Situation finden, können Sie dies sofort melden..",
    },
    home: {
        title: "",
        Total_assets: "Gesamte umgewandelte Vermögenswerte",
        Recharge: "Geld aufladen",
        documentary: "Dokumentarfilm",
        transaction: "Transaktion",
        IEO: "IEO",
        Lock_mining: "Abbau sperren",
        Investment_Financing: "Investitionen und Vermögensverwaltung",
        Currency_transfer: "Währungstransfer",
        Currency_swap: "Swap",
        Withdrawal: "Bargeldabhebung",
        Blind_box: "Blindbox",
        Guess: "raten",
        Daily_tasks: "Tägliche Aufgaben",
        Sign_task: "Aufgabe signieren",
        Buy_coins_quickly: "Schneller Münzkauf",
        Buy_quickly: "Schnellkauf",
        day: "Tag",
        rise: "steigen",
        Daily_yield: "Tagesertrag",
        quotation: "Markt",
        Transaction_pair: "Transaktionspaare",
        Latest_price: "Neuester Preis",
        Rise_fall: "Die Höhen und Tiefen von heute",
    },
    ieo: {
        ieo: "IEO Abo",
        Lock_up_cycle: "Sperrfrist",
        day: "Tag",
        Closed: "Beendet",
        Completed: "Abgeschlossen",
        Subscribed: "Abonniert",
        surplus: "Überschuss",
        My_subscription: "Mein Abonnement",
        Subscription_time: "Abonnementzeit",
        currency: "Währung",
        Requested_number: "Anwendungsmenge",
        Pass_number: "Durch Menge",
        Market_time: "Anzeigezeit",
    },
    Invest: {
        conduct: "Finanzierung",
        Special_area: "Zone",
        Annualized_rate: "Jährliche Rendite",
        Linked_price: "Verknüpfter Referenzpreis",
        Remaining_share: "Restanteil",
        Position_duration: "Haltungszeitraum",
        due_date: "Fälligkeit",
        My_conduct: "Meine Finanzverwaltung",
        all: "ganz",
        fail: "fehlschlagen",
        Purchased: "Gekauft",
        settlement: "Abwicklung im Gange",
        Completed: "Abgeschlossen",
    },
    Lockming: {
        Minimum_single: "Minimale Einzeltransaktion",
        Daily_yield: "Tagesertrag",
        Lock_up_cycle: "Sperrfrist",
        payout_time: "Auszahlungszeit der Dividende",
        everyday: "jeden Tag",
        Custody_funds: "Treuhandfonds",
        Return_expiration: "Rückgabe des Ablaufs",
        called_away: "weggerufen",
        placeholder: "Bitte Inhalt eingeben",
        all: "ganz",
        Estimated_income: "Erwartete Einnahmen",
        Available_assets: "Verfügbare Vermögenswerte",
        subscription: "Abo",
        Lock_mining: "Abbau sperren",
        Funds_custody: "Fonds in Verwahrung",
        Estimated_yield_today: "Erwartete Erträge von heute",
        Accumulated_income: "Kumuliertes Einkommen",
        Orders_custody: "Beschlüsse in Gewahrsam",
        Delegated_Order: "Auftragserteilungen",
        Lock: "Schließt das Lager ab.",
        day: "Tag",
        Lock_list: "Sperrliste",
        Exited: "Beendet",
        Redeemed: "Erlöst",
        have_hand: "In Bearbeitung",
        redeem: "Einlösung",
        Amount_currency: "Anzahl der hinterlegten Münzen",
        Lockup_time: "Sperrzeit",
        Expiration_time: "Ablaufzeit",
        early_redemption: "Strafe für vorzeitige Rückzahlung",
    },
    Market: {
        Optional: "Selbstauswahl",
        Transaction_pair: "Transaktionspaare",
        Latest_price: "Neuester Preis",
        place_search: "Suchen Sie nach der Währung/dem Aktiennamen/dem Code, den Sie interessieren",
    },
    Setting: {
        label_currency: "Währung auswählen",
        place_currency: "Bitte wählen Sie eine Währung",
        label_walletTitle: "Name der Geldbörse",
        place_walletTitle: "Bitte geben Sie den Wallet-Namen ein",
        label_walletAddress: "Wallet-Adresse",
        place_walletAddress: "Bitte geben Sie die Wallet-Adresse ein",
        Add_bankCard: "Bankkarte hinzufügen",
        label_name: "Echter Name",
        label_depositBank: "Eröffnungsbank",
        label_bankCard: "Bankkartennummer",
        label_beneficary_address: "Adresse des Empfängers",
        label_payee_name: "Name des Zahlungsempfängers",
        label_bank_name: "Name der Bank",
        label_swift_code: "Bankleitzahl",
        APP_download: "MSB-Zertifikat",
        label_currentPassword: "Aktuelles Passwort",
        place_currentPassword: "Bitte geben Sie das aktuelle Passwort ein",
        label_newPassword: "Neues Login-Passwort",
        place_newPassword: "Bitte geben Sie ein neues Login-Passwort ein",
        label_confirmationPassword: "Neues Passwort bestätigen",
        place_confirmationPassword: "Bitte geben Sie ein bestätigtes neues Passwort ein",
        label_newPassword1: "Transaktionskennwort",
        place_newPassword1: "Bitte geben Sie das Transaktionspasswort ein",
        payment_notice: "Hinweis: Der anfängliche Standardwert des Transaktionskennworts ist das aktuelle Anmeldekennwort",
        Real_mark1: "Authentifizierung der persönlichen Identität",
        Real_mark2: "Nach Abschluss der Authentifizierung erhaltene Berechtigungen:：",
        Real_mark3: "Lv1.Grundzertifizierung",
        Real_mark4: "Nach der Zertifizierung kann sich zeigen, dass das 24-Stunden-Limit 200BTC istTC",
        Real_mark5: "Nach der Authentifizierung können legale Währungstransaktionen mit einem einzigen Limit von 2000USDT durchgeführt werden",
        Real_mark6: "Lv2.Erweiterte Zertifizierung",
        Real_mark7: "Erhöhen Sie das Auszahlungslimit mit einem 24-Stunden-Limit von 500BTC",
        Real_mark8: "Erhöhen Sie das gesetzliche Währungstransaktionslimit mit einem einzigen Transaktionslimit von 1000000USDT",
        Real_mark9: "Bitte beachten Sie: Um die Sicherheit der Benutzergelder zu schützen, füllen Sie bitte zuerst die Bindung von E-Mail und Telefon aus, sonst wird Ihre Authentifizierung nicht weitergegeben.",
        Real_mark10: "Grundzertifizierung",
        Real_mark11: "Zertifiziert",
        Real_mark12: "Erweiterte Zertifizierung",
        Real_mark13: "Zur Authentifizierung",
        label_realName: "Name",
        message_realName: "Bitte geben Sie Ihren richtigen Namen ein",
        label_cardNumber: "ID-Nummer",
        message_cardNumber: "Bitte geben Sie die ID-Nummer ein",
        upload_front: "Laden Sie die Vorderseite des Personalausweises hoch",
        upload_back: "Laden Sie die Rückseite Ihres Personalausweises hoch",
        upload_people: "Personalausweis hochladen",
        under_review: "Wird geprüft",
        security_setting: "Sicherheitseinstellung",
        my_bank: "Meine Bankkarte",
        Change_Loginpsd: "Login Passwort ändern",
        Change_transactionpsd: "Transaktionskennwort ändern",
        email: "Postfach",
        my: "Meine",
        Bank: "Bankkarte",
        AddBank: "Bankkarte hinzufügen",
        walletAddress: "Wallet-Adresse",
        Default_currency: "Standardwährung",
        language: "Sprache",
        share: "Teilen",
        logout: "Verlassen",
        qrcode: "Mein Qrcode",
        personal: "persönliche Informationen",
        identity: "Identitätsauthentifizierung",
        nickname: "Spitzname",
        nickname_tip: "2-10 Zeichen, unterstützt Chinesisch und Englisch, Zahlen",
        Add_newAccount: "Neues Konto hinzufügen",
        label_user: "Benutzername",
        label_number: "Bankkartennummer",
        label_branch: "Bankadresse",
        label_bank: "Name der Bank",
        label_swift: "Swift Codierung",
        label_address: "Benutzeradresse",
    },
    Task: {
        daily_tasks: "Tägliche Aufgaben",
        activity_today: "Die heutige Veranstaltung",
        task_list: "Aufgabenliste",
        weekly_tasks: "Wöchentliche Aufgaben",
        activity: "Aktivität",
        undone: "Unfertig",
        receive: "empfangen",
        task_rules: "Aufgabenregeln",
        task_rules1: "1：Aktivitäten sammeln sich innerhalb einer Woche an und werden automatisch zurückgesetzt und jeden Montag um 0Uhr wieder abgerechnet",
        task_rules2: "2：Schließe alle vier täglichen Aufgaben jeden Tag ab und verdiene fünf CCA-Münzen, nachdem du 100-Aktivitätslevel erreicht hast;；",
        task_rules3: "3： Wenn die Aktivität 350 erreicht, können Sie 10 CCA Münzen erhalten",
        task_rules4: "4： Wenn die Aktivität 700 erreicht, können Sie die Bergbaumaschine N1 für einen Tag erleben",
        sign_task: "Anmeldeaufgabe",
        Sign_everyDay: "Jeden Tag einloggen",
        CCA_coin: "CCA Münze",
        signIn: "Login",
        day: "Tag",
        daily_checkIn: "Täglicher Check-in",
        put_away: "Pack zusammen",
        Expand: "Erweiterung",
        signIn_now: "Jetzt anmelden",
        checkIn_rules: "Check-in Regeln",
        checkIn_rules1: "1： Melden Sie sich für fünf aufeinanderfolgende Tage an und erhalten Sie 5 CCA-Münzen",
        checkIn_rules2: "2：Melden Sie sich für 14-Tage in Folge an und erhalten Sie 20-CCA-Münzen",
        checkIn_rules3: "3： 21-Tage hintereinander Check-in, belohnen N1 Mining Maschine mit drei Tagen Erfahrung Zeit",
        checkIn_rules4: "4： Halten Sie nach 24-aufeinanderfolgenden Check-in-Tagen ununterbrochene Check-in-Aufzeichnungen und verdienen Sie fünf CCA-Münzen pro Check-in-Tag",
    },
    Transaction: {
        delegate_List: "Delegierte Liste",
        risk_rate: "Risikoquote",
        total_income: "Gesamtertrag der Betriebe",
        oneClick_closing: "Schließposition mit einem Klick",
        optional: "Selbstauswahl",
        all_closed: "Vollständige Liquidation",
        flat_multiple: "Nur Ping Duo Dan",
        flat_empty: "Nur flache Luft einzeln",
        purchase: "Kaufen",
        sellOut: "Verkaufen",
        market_price: "Marktpreis",
        fixed_price: "Preisbegrenzung",
        trading_hands: "Anzahl der Handelshände",
        multiple: "mehrfach",
        bond: "Marge",
        Service_Charge: "Bearbeitungsgebühren",
        balance: "Saldo",
        long: "Go long",
        Short: "Leerverkäufe",
        Price: "Preis",
        number: "Menge",
        type: "Typ",
        time: "Zeit",
        operation: "Betrieb",
        Transaction_pair: "Transaktionspaare",
        direction: "Richtung",
        search: "Suche",
        Latest_price: "Neuester Preis",
        Rise_fall: "Die Höhen und Tiefen von heute",
        entrust: "vertrauen",
        all: "ganz",
        entrust_time: "Vertrauenszeit",
        state: "Zustand",
        Completed: "Abgeschlossen",
        air_completed: "komplett",
        place_amount: "Bitte Inhalt eingeben",
        turnover: "Transaktionsvolumen",
        contract: "Vertrag",
        option: "Option",
        coins: "Münzen",
        High_today: "Heute hoch",
        Low_today: "Heute niedrig",
        Entrusted_bill: "Auftragserteilung",
        Buy_more: "Buydoo",
        Buy_less: "Weniger kaufen",
        buy: "kaufen",
        sell: "verkaufen",
        crm_order: "Auftragsbestätigung",
        Current_price: "Aktueller Preis",
        expiration_time: "Ablaufzeit auswählen",
        Option_position: "Optionspositionen",
        position: "Position halten",
        history: "Geschichte",
        Purchase_price: "Kaufpreis",
        Purchase_amount: "Kaufbetrag",
        Order_duration: "Auftragsdauer",
        Selling_time: "Verkaufszeit",
        Profit_loss: "Gewinn und Verlust",
        Hand_count: "Handzählung",
        countdown: "Countdown",
        order_number: "Bestellnummer",
        currency: "Währung",
        reales_Number: "Tatsächliche Transaktion",
        total_price: "Gesamtpreis",
        close_price: "Schlusspreis",
        volume_trade: "Handelsvolumen",
        hand_fee: "Bearbeitungsgebühren",
        continue_order: "Weiter Bestellungen aufgeben",
    },
    footer: {
        home: "Startseite",
        market: "Markt",
        transaction: "Transaktion",
        financial: "Finanzierung",
        fund: "Vermögenswert",
        rule: "Regel",
        select_language: "Bitte wählen Sie eine Sprache"
    },
    common: {
        noData: "Derzeit liegen keine relevanten Daten vor",
        Exchange: "Austausch",
        Cancel: "Abbrechen",
        Confirm: "bestätigen",
        Submit: "Absenden",
        more: "mehr",
        add: "Hinzufügen",
        Cover: "Verbergen",
        Success: "Erfolg",
        portion: "Teilen",
        day: "Tag",
        please_select: "Bitte wählen",
        no_data: "Keine Daten",
    },
    script: {
        error_msg: "Zahlen/Buchstaben",
        register_noAccount: "Bitte geben Sie die Telefonnummer oder E-Mail-Adresse des Benutzers ein",
        send_phone_scuccess: "Erfolgreich gesendete SMS!",
        register_success: "Anmeldung war erfolgreich！",
        please_enter_amount: "Bitte geben Sie den Betrag ein！",
        please_enter_money: "Bitte geben Sie die Kaufgelder ein！",
        success: "Erfolg!",
        bankId_msg: "Bitte wählen Sie ein Wallet",
        amount_msg: "Bitte geben Sie den Betrag ein",
        password_msg: "Bitte geben Sie das Passwort ein",
        redemption_msg: "Bist du dir sicher, dass du früher erlöst wirst??",
        tip: "prompt",
        redemption_success: "Einlösung erfolgreich!",
        canceled: "Abgebrochen",
        realname_submit_success: "Realer Name erfolgreich eingereicht!",
        logout_tip: "Sind Sie sicher, dass Sie sich abmelden möchten?？",
        login_tip: "Sind Sie sicher, dass Sie sich einloggen möchten?？",
        signIn: "Login",
        closeout_tip: "Alle Abschlusspositionen bestätigen?",
        closeout_success: "Alle Positionen erfolgreich abgeschlossen!",
        cancel_tip: "Sind Sie sicher, dass Sie stornieren möchten??",
        notice: "Mitteilung",
        insufficient_balance: "Unzureichendes Gleichgewicht!",
    },
};
