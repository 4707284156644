export default function lang() {
    let lang = navigator.language; //浏览器语言判断
    lang = lang.substr(0, 2);
    switch (lang) {
        case "en": //英文
            lang = "en";
            break;
        // case "zh": //中文
        //     lang = "zh";
        //     break;
        case "tc": //中文
            lang = "tc";
            break;
        case "hd": //印地语
            lang = "hd";
            break;
        case "sp": //西班牙
            lang = "sp";
            break;
        case "pg": //葡萄牙
            lang = "pg";
            break;
        case "jp": //日语
            lang = "jp";
            break;
        case "ko": //韩语
            lang = "ko";
            break;
        case "fr": //法语
            lang = "fr";
            break;
        case "it": //意大利语
            lang = "it";
            break;
        case "ge": //德语
            lang = "ge";
            break;
        case "rs": //俄语
            lang = "rs";
            break;
        case "vi": //越南
            lang = "vi";
            break;
        case "th": //泰语
            lang = "th";
            break;
        default:
            lang = "en";
    }
    return lang;
}