import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

//导入语言包
import En from '../lang/en.js'; // 英文语言包
// import Zh from '../lang/zh.js'; // 中文语言包
import Tc from '../lang/tc.js'; // 中文繁体语言包
import Hd from '../lang/hindi'; // 印地语言包
import Sp from '../lang/spanish'; // 西班牙语言包
import Pg from '../lang/portuguese'; // 葡萄牙语言包
import Jpn from '../lang/jpn'; // 日语语言包
import Ko from '../lang/korean'; // 韩语语言包
import Fr from '../lang/french'; // 法语语言包
import It from '../lang/italian'; // 意大利语言包
import Ge from '../lang/german'; // 德语语言包
import Rs from '../lang/russian'; // 俄语语言包
import Vi from '../lang/vietnam'; // 越南语言包
import Th from '../lang/th.js'; // 泰语


const messages = {
    en: En, // 英文语言包
    // zh: Zh, // 中文语言包
    tc: Tc, // 中文语言包
    hd: Hd, // 印地语言包
    sp: Sp, // 西班牙语言包
    pg: Pg, // 葡萄牙语言包
    jp: Jpn, // 日语语言包
    ko: Ko, // 韩语语言包
    fr: Fr, // 法语语言包
    it: It, // 意大利语言包
    ge: Ge, // 德语语言包
    rs: Rs, // 俄语语言包
    vi: Vi, // 越南语言包
    th: Th, // 泰语
}
window.localStorage.setItem("lang", 'en')
    // window.sessionStorage.setItem("lang", 'zh')
export default new VueI18n({
    locale: 'en', // set locale 默认显示英文
    fallbackLocale: 'en', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});