export default {
    account: {
        more: {
            rechargeAddress: "チャージアドレス",
            rechargeAddressPlace: "チャージアドレスを入力してください",
            service: "応急処置",
            moreMin: "最低出金額を下回らないこと",
            placePrefix: "国名を選択してください",
        },
        aboutUs: {
            lint1: "At BTCWEB, we look at investing differently, Today's financial system is complex, exclusive and expensive, making it difficult for the average person to start investing,",
            lint2: "So we're building a platform without complex barriers, a platform that enables you to see new money possibilities and help them become a reality,",
            lint3: "BTCWEB is designed to make investing accessible to everyone, so from day one our goal has been to enable novices or seasoned experts to invest in the cryptocurrencies they want with easy-to-use financial products,",
            lint4: "BTCWEB strictly abides by the laws and regulations of various regions, We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets, ",
            lint5: "BTCWEB is one of the fastest growing fintech companies, We have a world-class expert team and the best technical talents to ensure that users' digital assets are always their own and to consolidate our position in the cryptocurrency trading market,",
        },
        billTypes: [
            {v: 101, name: 'リチャージ'},
            {v: 102, name: 'デポジット'},
            {v: 103, name: '凍結'},
            {v: 104, name: 'デフロスト'},
            {v: 105, name: 'デポジット'},
            {v: 106, name: 'デポジット'},
            {v: 201, name: '引出しの凍結'},
            {v: 202, name: '出金'},
            {v: 203, name: '出金に成功'},
            {v: 204, name: '現金の引き出しに失敗した'},
            {v: 205, name: '出金手数料'},
            {v: 206, name: 'トランスファー・アウト'},
            {v: 207, name: 'に転送します。'},
            {v:208,name:'貨幣転出'},
            {v:209,name:'貨幣転入'},
            {v:301,name:'貨幣手数料'},
            {v:302,name:'契約収益'},
            {v:303,name:'契約損失'},
            {v:311,name:'オプションの購入'},
            {v:312,name:'オプション収益'},
            {v:313,name:'オプション返品'},
            {v:314,name:'オプション手数料'},
            {v:315,name:'貨幣購入凍結'},
            {v:316,name:'貨幣購入控除'},
            {v:317,name:'貨幣購入リターン'},
            {v:318,name:'貨幣購入入金'},
            {v:319,name:'貨幣購入入金'},
            {v:320,name:'貨幣販売凍結'},
            {v:321,name:'貨幣売却控除'},
            {v:322,name:'貨幣売り戻'},
            {v:323,name:'貨幣が帳簿に売られた'},
            {v:324,name:'貨幣が帳簿に売られた'},
            {v:325,name:'貨幣手数料'},
            {v:401,name:'鉱機参入'},
            {v:402,name:'鉱機リターン'},
            {v:403,name:'鉱山収益'},
            {v:404,name:'鉱機退出'},
            {v:405,name:'鉱機退出手数料'},
            {v:501,name:'ブラインドボックス購入'},
            {v:502,name:'ブラインドボックス収入'},
        ],
        withdrawTypes: [
            { v: 0, name: 'アプリケーション実行中' },
            { v: 1, name: '治療中' },
            { v: 2, name: 'サクセス' },
            { v: 3, name: '失敗例' },
        ],
        types: [
            { v: 4, name: 'オプション口座' },
            { v: 3, name: 'フランス通貨口座' },
            { v: 2, name: 'コントラクト口座' },
            { v: 1, name: '通貨口座' },
        ],
        login: {
            aboutUs: "私たちについて",
            notice: "簡単にサービスを追加したい場合は,オンラインカスタマーサービスにご連絡ください。",
            login: "ログイン＃ログイン＃",
            selectLanguage: "言語の選択",
            placeEmail: "電子メールまたは携帯電話",
            placePsd: "ひそかに言う",
            noAccount: "アカウントはありませんか？行く",
            register: "登録",
            btnLogin: "ログイン＃ログイン＃",
            forget: "忘れる?",
        },
        register: {
            register: "登録",
            selectLanguage: "言語の選択",
            placeEmail: "Eメール",
            placePsd: "ひそかに言う",
            placeCrmPsd: "パスワードの確認",
            placeInviteId: "招待コード",
            haveAccount: "すでに口座はありますか？行く",
            login: "ログイン＃ログイン＃",
            btnRegister: "登録",
            getCode: "認証コードの取得",
            code: "認証コード",
            psd_error: "もう一度パスワードを入力してください",
            psd_error2: "一貫性のない2つのパスワード",
            email_error: "メールボックスを空にすることはできません！",
            email_error2: "メールの形式が正しくない！",
            elail_register: "Eメール",
            phone_register: "電話",
            country: "国",
            placePhone: "電話番号",
            countryCode: "国コード",
            pleaseEnter: "入力してください"
        },
        forget: {
            title: "パスワードを忘れる",
            labelEmail: "Eメール",
            labelEmailCode: "Eメール認証コード",
            placeEmailCode: "Eメール認証コード",
        },
        forgetPay: {
            title: "取引パスワードの忘れ",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "宝くじの記録",
        },
        Blind_box_voucher: {
            title: "金券",
            voucher: "金券",
            currentVoucher: "現在の買掛伝票",
        },
        Blind_box: {
            title: "ブラインドボックス",
            goodluck: "万事順調をお祈りします。",
            panelTit: "下のブラインドボックスを選択してください",
            tip1: "1回の抽選で2500枚のクーポンを獲得する必要があります",
            tip2: "あなたにはまだ2500回のチャンスがあります",
            limit1: "本日の残り抽選",
            limit2: "10回",
            times: "回数",
            draw: "の絵をかく",
            discount: "割引",
            payable: "対処",
            blind_box: "ブラインドボックス",
            available: "入手可能",
            lotteryRecord: "宝くじの記録",
            Determine_blind_box: "ブラインドボックスを決定するには",
            tip3: "2, 1回の宝くじ抽選は1日10回,連続抽選は5回に限らない",
            tip4: "3, 両替クーポンはEXCHANGでBTCとETHを両替できます",
            tip5: "4, 宝くじは毎回当たります",
            tip6: "5, 10000回を超える5回のUSDT引き出しは「引換券」を獲得しなければならず,5000回を超える10回のUSDT引き出しは「引換券」を獲得しなければならず,2500回以上の20回のUSDT抽選は「引換券」を獲得しなければならない",
            tip7: "5ドル宝くじの賞金と確率:",
            tip8: "1, 15～200 OTD,確率50%",
            tip9: "2, 1 ~ 5個のEOS,確率は10%",
            tip10: "3, 5～30 XRP,確率10%",
            tip11: "4, 1 ~ 10 USDT,確率10%",
            tip12: "5, 10～50 DOGE,確率10%",
            tip13: "6, 3 ETH,確率5%",
            tip14: "7, 10 ETH,確率2%",
            tip15: "8, 20 ETH,確率2%",
            tip16: "9, 2 BTC,確率1%",
            tip17: "10 USDT抽選賞金と確率:",
            tip18: "1, 30～600 OTD,確率50%",
            tip19: "2, 1 ~ 20 EOS,確率10%",
            tip20: "3, 10 ~ 80 XRP,確率10%",
            tip21: "4, 5～30ドル/トン,確率10%",
            tip22: "5, 20～100 DOGE,確率10%",
            tip23: "6, 20 ETH,確率4%",
            tip24: "7, 1 BTC,確率4%",
            tip25: "8, 2 BTC,確率2%",
            tip26: "20 USDT抽選賞金と確率:",
            tip27: "1, 5～50 USDT,確率30%",
            tip28: "2, 1~50 USDT,確率30%",
            tip29: "3, 1 ~ 20 EOS,確率10%",
            tip30: "4, 3 DOT,確率5%",
            tip31: "5, 1つのXMR,確率は5%",
            tip32: "6, 1 BCH,確率5%",
            tip33: "7, 1 ETH,確率5%",
            tip34: "8, 確率5%の10000 DOGE",
            tip35: "9, 2 BTC，3%確率",
            tip36: "10, 5 BTC,確率2%",
            tip37: "ラッキー抽選方式:",
            tip38: "1, 5 USDTは1回,10 USDTは2回,20 USDTは1回,5回連続すると5%の割引が受けられる",
        },
    },
    Financial: {
        index: {
            finance: "資金",
            ieo: "IEO社",
            LockMining: "ロック採鉱",
            day: "昼",
            unit: "じょうしょう",
            Daily_yield: "日次生産高",
        },
    },
    Follow: {
        index: {
            title: "トレーダーリスト",
            tip: "データは1時間ごとに更新されます",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "財務記録",
            number: "数値＃スウスウ＃",
            time: "時間",
        },
        Assets_record: {
            title: "アカウントの詳細",
            total_assets: "変換された総資産",
            Available_Credit: "利用可能なクレジット",
            locking: "ペンディング",
            Financial_records: "財務記録",
            number: "数値＃スウスウ＃",
            time: "時間",
        },
        Assets: {
            Account_record: "アカウント変更履歴",
            Recharge: "じゅうでん",
            Collection: "コレクション",
            Withdrawal: "撤回",
            transfer: "トランスファ",
            Currency_transfer: "通貨振替",
            Coins: "コイン枚",
            contract: "契約書",
            Legal_currency: "法定通貨",
            option: "オプション",
            Equivalent: "に等しい",
        },
        FundTransfer: {
            title: "資金移転",
            Select_Currency: "通貨の選択",
            from: "から",
            Transfer_quantity: "転送数量",
            to: "まで",
        },
        Receive_withdraw_record: {
            title: "レコード破り",
        },
        Receive: {
            title: "じゅうでん",
            Digital_currency: "デジタル通貨",
            tip: "カスタマーサービスに連絡してチャージ先を尋ねる",
            next: "次のステップ",
            Recharge_address: "チャージアドレス",
            Recharge_number: "チャージ数量",
            place_Recharge_number: "チャージ数量を入力してください",
            Payment_voucher: "支払証憑",
            place_Payment_voucher: "支払証憑を入力してください",
        },
        Transfer: {
            Currency_transfer: "通貨振替",
            Capital_transfer: "資金移転",
            from: "から",
            Transfer_number: "転送数量",
            need_service_charge: "追加サービス料",
            to: "まで",
            will_get: "あなたは",
            Available_Credit: "利用可能なクレジット",
            Exchange_rate: "為替レート",
            Service_Charge: "サービス料",
        },
        Withdraw: {
            title: "振替口座",
            Withdrawal: "撤回",
            bank_card: "銀行カード",
            wallet_address: "ウォレットアドレスについて",
            Withdrawal_number: "引き出し数量",
            available: "入手可能",
            place_Withdrawal_number: "引き出し数量を入力してください",
            all: "すべての",
            Minimum_Withdrawal_number: "最小引き出し量",
            password: "アカウントパスワード",
            Service_Charge: "サービス料",
        },
    },
    Guess: {
        title: "推測する",
        min: "最小値",
        Bullish: "強弱を頼む",
        Bearish: "ひげのよう",
        Guess_amount: "推測金額",
        place_Guess_amount: "金額を入力してください",
        Current_selection: "現在の選択",
        Payable: "対処",
        Guess_now: "今当ててみて",
        Available: "入手可能",
        Lottery_record: "宝くじの記録",
        Betting_record: "ベットレコード",
        Start_price: "開始価格",
        End_price: "最終価格",
        Game_introduction: "ゲーム紹介",
        tip1: "1,クイズは10分,3分,1分の3つの期間に分かれています。BTCの上昇と下降。BTCの価格はCoinbase+Binance+Huobiのリンク先の平均価格です。これは完全に公平な小説です。",
        tip2: "2,ここでは10分間の例として,各期間の最初の10分間は注文,後半の2分間は入札,最後の1分間は入札価格の発表で,入札＞入札価格は「上昇」であるとします。 例えば,00:00~00:15,ここで一日の最初の期間,00:00にあなたは1000 USDTでBTCの「コール」注文をします。時間が00:13に達すると,BTCの価格は35000 USDTであり,あなたのマーク価格は：35000 USDTです。 そして,00:14に公開された結果価格は35001USDTなので,推測は「上」,正解は「強気」です。 当てられた方,おめでとうございます。 あなたは950ドルの利益を受け取ることができます。 システムは1950USDTであなたに決済し,そのうち50USDTがプラットフォームとなり,あなたは950USDTの利益を得ることができます」。",
        tip3: "3,確率は1:0,95ですが,なぜ1:1ではないのですか？ プラットフォームが5%の手数料を取るからです。",
        tip4: "4,結果はクイズ終了時に清算されます。 あなたが勝ったお金は,敗者からあなたに渡されます。 プラットフォームがユーザー間のギャンブルに関与しないように,勝者が多く敗者が少ない場合は,敗者が現れるのを待ってから賞金をお渡しする必要があります。 例えば,100,000USDTを賭け,勝つと推測した場合,95,000USDTを受け取るはずです。しかし,80,000USDTだけが失われ,デリバリープールには95,000USDTを支払うだけのお金がありません。あなたはデリバリープールが敗者のお金を表示するまで,待つ必要があります。 あなたが15,000USDTに達すると,プラットフォームはあなたの95,000ボーナスを決済します。",
        tip5: "「5,どうしても待ちきれず,早くボーナスを取りたい場合は,クイックデリバリーを行うことができますが,クイックデリバリーは一般ボーナスに相当するため,95000USDTの25％しか受け取れず,つまり2375USDTしか受け取ることができません。 その力はプラットフォームに移り,プラットフォームが相対的なリスクを負う必要があるので,プラットフォームは莫大な手数料を差し引く必要があります。",
        tip6: "このQ&Aはプラットフォームとユーザー間の賭け事に関するものではありません,もしそのような状況を見つけたら,すぐに報告することができます。",
    },
    home: {
        title: "ツールボックス",
        Total_assets: "変換された総資産",
        Recharge: "じゅうでん",
        documentary: "ドキュメンタリー映画",
        transaction: "取り引き",
        IEO: "IEO社",
        Lock_mining: "ロック採鉱",
        Investment_Financing: "投資融資",
        Currency_transfer: "通貨振替",
        Currency_swap: "スワップ",
        Withdrawal: "撤回",
        Blind_box: "ブラインドボックス",
        Guess: "推測する",
        Daily_tasks: "日常的なタスク",
        Sign_task: "署名タスク",
        Buy_coins_quickly: "コインの迅速な購入",
        Buy_quickly: "クイック購入",
        day: "昼",
        rise: "じょうしょう",
        Daily_yield: "日次生産高",
        quotation: "参照＃サンショウ＃",
        Transaction_pair: "取引ペア",
        Latest_price: "最新価格",
        Rise_fall: "全員起立",
    },
    ieo: {
        ieo: "IEO購読",
        Lock_up_cycle: "ロックサイクル",
        day: "昼",
        Closed: "閉じる",
        Completed: "完全な",
        Subscribed: "購読済み",
        surplus: "余剰金",
        My_subscription: "マイ購読",
        Subscription_time: "購読時間",
        currency: "通貨",
        Requested_number: "要求された数量",
        Pass_number: "通行回数",
        Market_time: "市場時間",
    },
    Invest: {
        conduct: "金融取引を行う",
        Special_area: "特殊領域",
        Annualized_rate: "ねんかりつ",
        Linked_price: "関連価格",
        Remaining_share: "残りのシェア",
        Position_duration: "ポジション期間",
        due_date: "期日",
        My_conduct: "マイ財務管理",
        all: "すべての",
        fail: "に失敗",
        Purchased: "購入する",
        settlement: "プロトコル",
        Completed: "完全な",
    },
    Lockming: {
        Minimum_single: "最低単一取引",
        Lock_up_cycle: "ロックサイクル",
        payout_time: "支払い時間",
        everyday: "毎日の",
        Custody_funds: "供託資金",
        Return_expiration: "期限付き返却",
        called_away: "呼び出された",
        placeholder: "内容を入力してください",
        all: "すべての",
        Estimated_income: "予想収益",
        Available_assets: "利用可能な資産",
        subscription: "購読",
        Lock_mining: "ロック採鉱",
        Funds_custody: "供託資金",
        Estimated_yield_today: "今日の予想収益",
        Accumulated_income: "累計収益",
        Orders_custody: "管理対象受注",
        Delegated_Order: "発注",
        Lock: "ロック",
        day: "昼",
        Lock_list: "リストをロック",
        Exited: "終了しました",
        Redeemed: "の埋め合わせを",
        have_hand: "手元にある",
        redeem: "請け出す",
        Amount_currency: "通貨金額",
        Daily_yield: "日次生産高",
        Lockup_time: "ロック時間",
        Expiration_time: "有効期限",
        early_redemption: "早期償還の違約賠償金",
    },
    Market: {
        Optional: "オプション",
        Transaction_pair: "取引ペア",
        Latest_price: "最新価格",
        place_search: "関心のある通貨/株式名/コードの検索",
    },
    Setting: {
        label_currency: "通貨の選択",
        place_currency: "通貨を選択してください",
        label_walletTitle: "ウォレット名",
        place_walletTitle: "ウォレット名を入力してください",
        label_walletAddress: "ウォレットアドレス",
        place_walletAddress: "ウォレットアドレスを入力してください",
        Add_bankCard: "銀行カードの追加",
        label_name: "実名",
        label_depositBank: "口座開設銀行",
        label_bankCard: "銀行カード番号",
        label_beneficary_address: "受取人住所",
        label_payee_name: "受取人名",
        label_bank_name: "銀行名",
        label_swift_code: "銀行コード",
        APP_download: "MSB認証",
        label_currentPassword: "現在のパスワード",
        place_currentPassword: "現在のパスワードを入力してください",
        label_newPassword: "新規ログインパスワード",
        place_newPassword: "新しいログインパスワードを入力してください",
        label_confirmationPassword: "新しいパスワードの確認",
        place_confirmationPassword: "確認のために新しいパスワードを入力してください",
        label_newPassword1: "取引パスワード",
        place_newPassword1: "取引パスワードを入力してください",
        payment_notice: "注意：取引パスワードの初期デフォルト値は,現在のログインパスワードです。",
        Real_mark1: "個人認証",
        Real_mark2: "認証後に使用可能な権限：",
        Real_mark3: "レベル1基本認証",
        Real_mark4: "認証後に反映でき,24時間上限200 BTC",
        Real_mark5: "認証後,外貨取引を行うことができ,1取引限度額は2000 USDT",
        Real_mark6: "二級高級認証",
        Real_mark7: "反映限度額を引き上げ,24時間限度は500 BTC",
        Real_mark8: "フランス通貨の取引限度額を引き上げ,1件当たりの限度額は1000000ドル",
        Real_mark9: "ヒント：ユーザーの資金安全を保護するために,まず電子メールと電話のバインドを完了してください。そうしないと,認証が失敗します。",
        Real_mark10: "基本的な資格認定",
        Real_mark11: "かくにん",
        Real_mark12: "高度な資格認定",
        Real_mark13: "資格認定の取消",
        label_realName: "実名",
        message_realName: "実名を入力してください",
        label_cardNumber: "身分証明書番号",
        message_cardNumber: "身分証明書番号を入力してください",
        upload_front: "身分証明書のアップロード正面",
        upload_back: "身分証明書の背面をアップロード",
        upload_people: "ハンドヘルドIDのアップロード",
        under_review: "審査中",
        security_setting: "セキュリティ設定",
        my_bank: "マイ銀行カード",
        Change_Loginpsd: "ログインパスワードの変更",
        Change_transactionpsd: "トランザクションパスワードの変更",
        email: "Eメール",
        my: "マイ",
        Bank: "銀行カード",
        AddBank: "銀行カードの追加",
        walletAddress: "ウォレットアドレス",
        Default_currency: "デフォルト法定通貨",
        language: "言語",
        share: "共有＃キョウユウ＃",
        logout: "ログアウト",
        qrcode: "私のQrcode",
        personal: "個人情報",
        identity: "認証",
        nickname: "ニックネーム",
        nickname_tip: "2～10文字,中国語,英語,数字をサポート",
        Add_newAccount: "新規アカウントの追加",
        label_user: "ユーザー名",
        label_number: "銀行カード番号",
        label_branch: "銀行住所",
        label_bank: "銀行名",
        label_swift: "Swift符号化",
        label_address: "ユーザアドレス",
    },
    Task: {
        daily_tasks: "日常的なタスク",
        activity_today: "今日のイベント",
        task_list: "タスク・リスト",
        weekly_tasks: "週次タスク",
        activity: "アクティビティ",
        undone: "未完了",
        receive: "受入",
        task_rules: "タスク規則",
        task_rules1: "1：アクティビティは1週間以内に累積的にオーバーレイされ,毎週月曜日午前0時に自動的にクリアされ,再決済されます。；",
        task_rules2: "2：毎日4つの日常任務を完成し,100活動レベルに達した後に5つのCCAコインを獲得する；",
        task_rules3: "3：アクティビティが350に達すると,10個のCCAコインを得ることができます；",
        task_rules4: "4： アクティビティが700に達すると,1日のN 1鉱山体験を得ることができます",
        sign_task: "ログインタスク",
        Sign_everyDay: "毎日ログイン",
        CCA_coin: "CCAコイン",
        signIn: "ログイン＃ログイン＃",
        day: "昼",
        daily_checkIn: "毎日チェックイン",
        put_away: "やめる",
        Expand: "拡大",
        signIn_now: "今すぐログイン",
        checkIn_rules: "チェックイン規則",
        checkIn_rules1: "1：5日間連続ログインすると1：5のCCAコインがもらえます",
        checkIn_rules2: "2：14日間連続ログインすると2：20 CCAコインがもらえます",
        checkIn_rules3: "3：21日間連続ログイン,N 1鉱山の体験時間を3日間奨励；",
        checkIn_rules4: "4：24日間連続でチェックインした後,チェックイン記録を途切れることなく維持し,毎日チェックインすると5枚のCCAコインを獲得することができる；",
    },
    Transaction: {
        delegate_List: "代表リスト",
        risk_rate: "リスク率",
        total_income: "職階総収入",
        oneClick_closing: "ワンクリックオフ",
        optional: "オプション",
        all_closed: "すべての場所を閉じる",
        flat_multiple: "単一オーダーと複数オーダーのみ",
        flat_empty: "フラットと空の手形のみ",
        purchase: "購入する",
        sellOut: "売り切れ",
        market_price: "市場価格",
        fixed_price: "固定価格",
        trading_hands: "取引先",
        multiple: "の数に総計がなる",
        bond: "きずな",
        Service_Charge: "サービス料",
        balance: "バランス",
        long: "長い",
        Short: "短い",
        Price: "価格",
        number: "数値＃スウスウ＃",
        type: "を選択してオプションを設定します。",
        time: "時間",
        operation: "アクティビティ",
        Transaction_pair: "取引ペア",
        direction: "方向",
        search: "検索けんさく",
        Latest_price: "最新価格",
        Rise_fall: "上昇-下降",
        entrust: "委任",
        all: "すべての",
        entrust_time: "委任時間",
        state: "ステータス",
        Completed: "完全な",
        air_completed: "エアコンプリート",
        place_amount: "内容を入力してください",
        turnover: "人事変更率",
        contract: "契約書",
        option: "オプション",
        coins: "コイン枚",
        High_today: "今日は高い",
        Low_today: "本日の安値",
        Entrusted_bill: "委託手形",
        Buy_more: "追加購入",
        Buy_less: "少なめに買う",
        buy: "買う",
        sell: "売る",
        crm_order: "注文の確認",
        Current_price: "現在の価格",
        expiration_time: "有効期限の選択",
        Option_position: "オプションの場所",
        position: "位置",
        history: "履歴",
        Purchase_price: "購入価格",
        Purchase_amount: "購入金額",
        Order_duration: "オーダー期間",
        Selling_time: "販売時間",
        Profit_loss: "損益",
        Hand_count: "手の数",
        countdown: "カウントダウン",
        order_number: "オーダー番号",
        currency: "Valuta",
        reales_Number: "実際の取引",
        total_price: "合計価格",
        close_price: "終値",
        volume_trade: "貿易高",
        hand_fee: "手数料",
        continue_order: "注文を続行",
    },
    footer: {
        home: "家",
        market: "市場",
        transaction: "取り引き",
        financial: "財政的",
        fund: "基金",
        rule: "ルール＃ルール＃",
        select_language: "言語を選択してください"
    },
    common: {
        noData: "関連データは一時的にありません",
        Exchange: "スワップ",
        Cancel: "キャンセル",
        Confirm: "かくにん",
        Submit: "送信",
        more: "詳細",
        add: "追加",
        Cover: "いんぺい",
        Success: "成功",
        portion: "ぶん",
        day: "日",
        please_select: "選択してください",
        no_data: "データなし",
    },
    script: {
        error_msg: "を入力してください" ,
        register_noAccount: "ユーザーフォンかユーザーEメールを入力してください！" ,
        send_phone_scuccess: "SMSの送信に成功しました！" ,
        register_success: "登録に成功しました!" ,
        please_enter_amount: "金額を入力してください！" ,
        please_enter_money: "購入資金を入力してください!" ,
        success: "成功です！" ,
        bankId_msg: "ウォレットを選択してください" ,
        amount_msg: "金額を入力してください" ,
        password_msg: "パスワードを入力してください" ,
        redemption_msg: "早期償還を確認しますか？" ,
        tip: "チップ" ,
        redemption_success: "引き換えに成功しました！" ,
        canceled: "キャンセルしました" ,
        realname_submit_success: "本名投稿成功!" ,
        logout_tip: "本当にキャンセルしますか？" ,
        login_tip: "本当にログインしますか？" ,
        signIn: "ログイン" ,
        closeout_tip: "本当に全てのポジションをクローズしますか？" ,
        closeout_success: "すべてのポジションを正常にクローズしました！" ,
        cancel_tip: "本当にキャンセルしますか？" ,
        notice: "お知らせ" ,
        insufficient_balance: "残高不足です！" ,
    },
};
